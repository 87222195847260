import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, AlertController } from '@ionic/angular';
import { ProgramService } from 'src/app/services/program/program.service';
import { UserService } from 'src/app/services/user/user.service';
import {
    StripeCardElementOptions,
    StripeElementsOptions,
    PaymentIntent,
} from '@stripe/stripe-js';
import { StripeCardNumberComponent, STRIPE_OPTIONS, StripeService } from 'ngx-stripe';
import { Router } from '@angular/router';
import { ReviewsService } from 'src/app/services/reviews/reviews.service';
import { catchError, tap } from 'rxjs';
import { Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { ModalController } from '@ionic/angular';
import { PaymentFormComponent } from '../payment-form/payment-form.component';
import { StorageService } from 'src/app/services/storage/storage.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentSummaryComponent } from '../payment-summary/payment-summary.component';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ShowEnrollmentComponent } from '../show-enrollment/show-enrollment.component';
import { CommunityGuidelinesComponent } from '../community-guidelines/community-guidelines.component';

@Component({
    selector: 'app-program-preview',
    templateUrl: './program-preview.component.html',
    styleUrls: ['./program-preview.component.scss']
})
export class ProgramPreviewComponent implements OnInit {
    @Input('courseDetails') courseDetails;
    @Input('isEditing') isEditing;
    @Input('isDocumentsUploaded') isDocumentsUploaded;
    @Input('fromPage') fromPage;
    mediaUrl: any = [];
    imageDocumentList: any = [];
    audioDocumentList: any = [];
    videoDocumentList: any = [];
    pdfDocumentList: any = [];
    // starRating: any = ['star', 'star', 'star', 'star-outline', 'star-outline'];
    // starRating: any = ['star-outline', 'star-outline', 'star-outline', 'star-outline', 'star-outline']
    @ViewChild('programDetailsContentWrapper', { static: false }) programDetailsContentWrapperElem: ElementRef;
    @ViewChild('programMediaWrapper', { static: false }) programMediaWrapperElem: ElementRef;
    @ViewChild('programDetailsOverlay', { static: false }) programDetailsOverlayElem: ElementRef;
    @ViewChild('programMediaOverlay', { static: false }) programMediaOverlayElem: ElementRef;
    @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

    isAuthorlogin: boolean = false;

    constructor(
        public userService: UserService,
        public alertController: AlertController,
        public programService: ProgramService,
        public modalCtrl: ModalController,
        private http: HttpClient,
        public storageService: StorageService,
        private fb: FormBuilder,
        private stripeService: StripeService,
        private paymentService: PaymentService,
        public navigationService: NavigationService,
        public router: Router,
        public actionSheetCtrl: ActionSheetController,
        public reviewService: ReviewsService
    ) {
    }

    ngOnInit() {
        console.log("🚀 ~ file: program-preview.component.ts:34 ~ ProgramPreviewComponent ~ ngOnInit ~ this.isEditing:", this.isEditing)
        console.log("🚀 ~ ProgramPreviewComponent ~ courseDetails:", this.courseDetails)
        console.log("🚀 ~ ProgramPreviewComponent ~ fromPage:", this.fromPage)
        this.allowJoin();
        console.log("Course Details ID: ", this.courseDetails._id)

        // );       console.log("Star Ratings:", this.starRating)
    }



    ngAfterViewInit() {
        this.shouldOverlayApplyToProgramDetailsContentWrapper();
        this.shouldOverlayApplyToProgramMediaWrapper();
        this.reviewService.getAvgStarRatingFunction(this.courseDetails._id)
    }

    shouldOverlayApplyToProgramDetailsContentWrapper() {
        setTimeout(() => {
            if (this.programDetailsContentWrapperElem.nativeElement.clientHeight == 192) this.programDetailsOverlayElem.nativeElement.style.display = 'flex';
            else this.programDetailsOverlayElem.nativeElement.style.display = 'none';
        }, 500);
    }

    shouldOverlayApplyToProgramMediaWrapper() {
        setTimeout(() => {
            if (this.programMediaWrapperElem.nativeElement.clientHeight == 192) this.programMediaOverlayElem.nativeElement.style.display = 'flex';
            else this.programMediaOverlayElem.nativeElement.style.display = 'none';
        }, 500);
    }


    getMediaUrl() {
        for (let i = 0; i < this.courseDetails.imageDocumentList.length; i++) {
            const media = this.courseDetails.imageDocumentList[i];
            this.imageDocumentList.push(URL.createObjectURL(this.courseDetails.imageDocumentList[i].file));
        }
        for (let i = 0; i < this.courseDetails.audioDocumentList.length; i++) {
            const media = this.courseDetails.audioDocumentList[i];
            this.audioDocumentList.push(URL.createObjectURL(this.courseDetails.audioDocumentList[i].file));
        }
        for (let i = 0; i < this.courseDetails.videoDocumentList.length; i++) {
            const media = this.courseDetails.videoDocumentList[i];
            this.videoDocumentList.push(URL.createObjectURL(this.courseDetails.videoDocumentList[i].file));
        }
        for (let i = 0; i < this.courseDetails.pdfDocumentList.length; i++) {
            const media = this.courseDetails.pdfDocumentList[i];
            this.pdfDocumentList.push(URL.createObjectURL(this.courseDetails.pdfDocumentList[i].file));
        }
    }

    expand(mainContentElem, mainContentWrapperElem, overlayElem) {
        if (mainContentElem?.clientHeight > 192) mainContentWrapperElem.style.maxHeight = 'max-content', overlayElem.style.display = 'none';
    }

    joinProgramClicked() {
        if (this.checkIfSeekerIsAuthorOfTheProgram()) this.joinProgram();
        else this.presentCannotJoinProgramAlert();
    }

    checkIfSeekerIsAuthorOfTheProgram() {
        return !(this.userService.currentUser._id == this.courseDetails.instructor_id);
    }

    async presentCannotJoinProgramAlert() {
        const cannotJoinProgramAlert = await this.alertController.create({
            cssClass: 'present-confirm-go-back-alert',
            message: 'You are author of the program. Cannot join as seeker.',
            buttons: [
                {
                    text: 'Okay',
                    handler: () => { },
                },
            ],
        });
        await cannotJoinProgramAlert.present();
    }

    async presentPaymentSummary() {
        try {
            var pamentuser = JSON.parse(this.storageService.getStorage('currentUser'));
            const openPaymentMOdal = await this.modalCtrl.create({
                component: PaymentSummaryComponent,
                cssClass: 'show-all-categories-modal',
                backdropDismiss: false,
                componentProps: {
                    courseDetails: this.courseDetails
                }
            });
            openPaymentMOdal.present();
            const { data, role } = await openPaymentMOdal.onWillDismiss();

            if (role === 'confirm') {
                this.openPayment(data);
            }
        } catch (error) {

        }
    }

    async presentCommunityGuidelines() {
        try {
            const CommunityGuidelinesModal = await this.modalCtrl.create({
                component: CommunityGuidelinesComponent,
                cssClass: 'show-all-categories-modal',
                backdropDismiss: true,
            });
            CommunityGuidelinesModal.present();
            const { data, role } = await CommunityGuidelinesModal.onWillDismiss();
            console.log("🚀 ~ file: program-preview.component.ts:171 ~ ProgramPreviewComponent ~ presentCommunityGuidelines ~ data:", data)
            if (data && data.val == 'yes') this.presentPaymentSummary();

        } catch (error) {

        }
    }

    async alertConfirmJoinProgramOld() {
        const alert = this.alertController.create({
            message: 'Confirm join program?',
            buttons: [
                {
                    text: 'No, cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes, Join',
                    handler: () => {
                        // this.openPayment();
                        this.presentPaymentSummary();
                    }
                }
            ]
        });
        (await alert).present();
    }

    joinProgram() {
        let joinProgramObj = {
            programId: this.courseDetails._id,
            seekerId: this.userService.currentUser._id,
            programAuthorId: this.courseDetails.instructor_id
        }

        this.programService.joinProgram(joinProgramObj).subscribe((res) => {
            if (res) this.presentJoinProgramSuccessfullAlert();
            console.log("🚀 ~ file: program-details.page.ts:55 ~ ProgramDetailsPage ~ this.programService.joinProgram ~ res:", res)
        })
    }

    async presentJoinProgramSuccessfullAlert() {
        const joinProgramSuccessfullAlert = await this.alertController.create({
            cssClass: 'present-confirm-go-back-alert',
            message: 'Program joined successfully',
            buttons: [
                {
                    text: 'Okay',
                    handler: () => { },
                },
            ],
        });
        await joinProgramSuccessfullAlert.present();
    }


    async openPayment(totalAmount) {
        try {
            var pamentuser = JSON.parse(this.storageService.getStorage('currentUser'));
            const openPaymentMOdal = await this.modalCtrl.create({
                cssClass: 'show-all-categories-modal',
                component: PaymentFormComponent,
                backdropDismiss: false,
                componentProps: {
                    ammount: totalAmount,
                    email: pamentuser.email,
                    full_name: pamentuser.full_name,
                    title: this.courseDetails.program_title,
                    programId: this.courseDetails._id,
                    authorId: this.courseDetails.instructor_id
                }
            });
            openPaymentMOdal.present();
            const { data, role } = await openPaymentMOdal.onWillDismiss();

            if (role === 'confirm') {
                this.allowjoinnowstatus = false;
            }
        } catch (error) {

        }
    }

    allowjoinnowstatus: boolean = false;
    allowJoin() {
        this.isAuthorlogin = (this.userService.currentUser._id == this.courseDetails.instructor_id) ? true : false;

        var currentuser = this.userService.currentUser._id;

        if (this.isAuthorlogin) {
            this.allowjoinnowstatus = false;
        }
        else if (!this.isAuthorlogin && !this.courseDetails.enrollments) {
            this.allowjoinnowstatus = true;
        }
        else {
            let index = this.courseDetails.enrollments.findIndex((seekerId) => seekerId === currentuser);
            console.log("enrollmentslistenrollmentslist0", index, this.courseDetails.enrollments[index])
            if (index >= 0) {
                this.allowjoinnowstatus = false;
            }
            else {
                this.allowjoinnowstatus = true;
            }
        }
        console.log("🚀 ~ file: program-preview.component.ts:250 ~ ProgramPreviewComponent ~ allowJoin ~ this.allowjoinnowstatus:", this.allowjoinnowstatus)
    }


    checkIfLoggedInUserIsProgramAuthor() {
        return JSON.parse(this.storageService.getStorage('currentUser'))._id == this.courseDetails.author_id
    }

    async OpenEnrollmentList() {

        try {
            const openPaymentMOdal = await this.modalCtrl.create({
                cssClass: 'show-enrollment-list-modal',
                component: ShowEnrollmentComponent,
                backdropDismiss: false,
                componentProps: {
                    program_id: this.courseDetails._id
                }
            });
            openPaymentMOdal.present();
            const { data, role } = await openPaymentMOdal.onWillDismiss();

            // if (role === 'confirm') {
            //     this.openPayment();
            // }
        } catch (error) {

        }
    }

    async presentOptionsActionSheet(announcement) {
        let authorOptions = [
            {
                text: 'Enrollement List',
                data: {
                    action: 'share',
                },
                handler: () => { this.OpenEnrollmentList(); }
            },
            {
                text: 'Edit Program',
                data: {
                    action: 'share',
                },
                handler: () => { this.editProgramDetails() }
            },
            {
                text: 'Close',
                role: 'cancel',
                data: {
                    action: 'cancel',
                },
            },
            {
                text: 'Cancel and Delete Program',
                role: 'destructive',
                data: {
                    action: 'delete',
                },
                handler: () => { }
            }
        ];

        let userOptions = [
            {
                text: 'Leave program',
                role: 'destructive',
                data: {
                    action: 'delete',
                },
                handler: () => { this.leaveProgram() }
            },
            {
                text: 'Close',
                role: 'cancel',
                data: {
                    action: 'cancel',
                },
            }
        ]

        let buttonOptions = this.isAuthorlogin ? authorOptions : userOptions;

        const actionSheet = await this.actionSheetCtrl.create({
            buttons: buttonOptions
        });

        await actionSheet.present();

        const result = await actionSheet.onDidDismiss();
    }

    async leaveProgram() {
        const leaveProgramSuccessfullAlert = await this.alertController.create({
            cssClass: '',
            header:'We’re sad to see you go.',
            message: ' We’ve got your request to leave the program and will be in touch soon to help with anything you need. We hope you find the perfect program that suits your needs!',
            buttons: [
                {
                    text: 'Okay',
                    handler: () => { },
                },
            ],
        });
        await leaveProgramSuccessfullAlert.present();
    }

    

    editProgramDetails() {
        this.navigationService.toEditProgram(this.courseDetails)
    }


    async mediaDetails(mediaList) {
        console.log(mediaList)
        this.router.navigate(['/display-image', this.courseDetails._id]);

        // const openPaymentMOdal = await this.modalCtrl.create({
        //     cssClass: 'show-enrollment-list-modal',
        //     component: DisplayImageComponent,
        //     componentProps: {
        //         mediaList: mediaList
        //     }
        // });
        // openPaymentMOdal.present();
        // const { data, role } = await openPaymentMOdal.onWillDismiss();


    }
}
