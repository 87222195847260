
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    baseServerUrl: any = environment.API_URL

    constructor(
        private http: HttpClient,
    ) { }

    createPaymentIntent(amount: number) {
        return this.http.post(this.baseServerUrl + `/payments/create-payment-intent`, { amount: amount }).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

    createStripeAccount() {
        return this.http.post(this.baseServerUrl + `/payments/provider-payment-account`, {}).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

    getOnboardingInstance(accountId) {
        return this.http.post(this.baseServerUrl + `/payments/provider-payment-session`, { account: accountId }).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

    getDashboardLink(accountId) {
        return this.http.post(this.baseServerUrl + `/payments/get-dashboard-link`, { accountId: accountId }).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

    savePaymentToDatabase(paymentObject) {
        return this.http.post(this.baseServerUrl + `/payments/save-payment`, paymentObject).pipe(
            tap((data) => { }),
            catchError((err) => { throw (err) }))
    }

}
