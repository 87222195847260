import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CategoriesService } from 'src/app/services/categories/categories.service';

@Component({
    selector: 'app-show-all-categories',
    templateUrl: './show-all-categories.component.html',
    styleUrls: ['./show-all-categories.component.scss']
})
export class ShowAllCategoriesComponent implements OnInit {
    categories: any = [];

    constructor(
        public categoriesService: CategoriesService,
        public modalCtrl: ModalController
    ) {
    }

    ngOnInit() {
        this.getCategories();
    }

    getCategories() {
        try {
            this.categoriesService.getCategories().subscribe((res: any) => {
                if (res) this.categories = res.data.categories;
            }, (error) => {
                console.log("🚀 ~ file: show-all-categories.component.ts:23 ~ ShowAllCategoriesComponent ~ this.categoriesService.getCategories ~ error:", error)
            })
        } catch (error) {
            console.log("🚀 ~ file: show-all-categories.component.ts:26 ~ ShowAllCategoriesComponent ~ getCategories ~ error:", error)
        }
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }
}
