import { AnnouncementsService } from 'src/app/services/announcements/announcements.service';
import { Component } from '@angular/core';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { AddAnnouncementComponent } from '../add-announcement/add-announcement.component';

@Component({
    selector: 'app-show-all-announcements',
    templateUrl: './show-all-announcements.component.html',
    styleUrls: ['./show-all-announcements.component.scss']
})
export class ShowAllAnnouncementsComponent {
    announcements;

    constructor(
        public modalCtrl: ModalController,
        public actionSheetCtrl: ActionSheetController,
        public alertController: AlertController,
        public announcementsService: AnnouncementsService
    ) { }

    ngOnInit() { }

    async alertConfirmDeleteAnnouncement(announcementId) {
        const alert = this.alertController.create({
            message: 'Are you sure you want to delete announcement?',
            buttons: [
                {
                    text: 'No, cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes, delete',
                    handler: () => {
                        this.removeAnnouncement(announcementId)
                    }
                }
            ]
        });
        (await alert).present();
    }

    async presentOptionsActionSheet(announcement) {
        const actionSheet = await this.actionSheetCtrl.create({
            buttons: [
                {
                    text: 'Delete',
                    role: 'destructive',
                    data: {
                        action: 'delete',
                    },
                    handler: () => { this.alertConfirmDeleteAnnouncement(announcement._id) }
                },
                {
                    text: 'Edit',
                    data: {
                        action: 'share',
                    },
                    handler: () => { this.editAnnouncement(announcement) }
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    data: {
                        action: 'cancel',
                    },
                },
            ],
        });

        await actionSheet.present();

        const result = await actionSheet.onDidDismiss();
    }

    async alertAnnouncementDeletedSuccessfully() {
        const alert = this.alertController.create({
            message: 'Announcement deleted successfully!!',
            buttons: [
                {
                    text: 'ok',
                    role: 'cancel',
                    handler: () => { this.closeModal() }
                }
            ]
        });
        (await alert).present();
    }

    async openAddAnnouncementModal(announcement) {
        try {

            const addAnnouncementModal = await this.modalCtrl.create({
                cssClass: 'add-announcement-modal',
                component: AddAnnouncementComponent,
                componentProps: {
                    announcement: announcement
                }
            });
            addAnnouncementModal.present();
        } catch (error) {

        }
    }

    removeAnnouncement(announcementId) {
        let updateObj = {
            'disable': true,
            updatedAt: new Date(),
        }
        this.announcementsService.UpdateAnnouncement(announcementId, updateObj).subscribe((res: any) => {
            if (res && res.success == true) this.alertAnnouncementDeletedSuccessfully();
        })
    }

    editAnnouncement(announcement) {
        this.openAddAnnouncementModal(announcement);
    }

    closeModal() {
        this.modalCtrl.dismiss()
    }

}
