import { Component, Input, OnInit } from '@angular/core';
import { IonLabel } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
    selector: 'app-program-section',
    templateUrl: './program-section.component.html',
    styleUrls: ['./program-section.component.scss'],
})
export class ProgramSectionComponent implements OnInit {

    @Input('sectionLabel') sectionLabel;
    @Input('sectionSubLabel') sectionSubLabel;
    @Input('programsList') programsList;
    constructor(
        public navigationService: NavigationService
    ) {
    }

    ngOnInit() {
        console.log("this.sectionLabel", this.sectionLabel, "this.sectionSubLabel", this.sectionSubLabel, "this.programsList", this.programsList)
    }

}
