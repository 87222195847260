import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { Platform } from '@ionic/angular';
import { LocationPage } from 'src/app/pages/location/location.page';
import { ModalController, AlertController } from '@ionic/angular';
import { CommonFunctionsService } from '../common/common-functions.service';
import { StorageService } from '../storage/storage.service';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

declare var google;

@Injectable({
    providedIn: 'root'
})
//verify platform
//start process
//first check permission
//if permission is present show lat long 
// if permission error ask to enter manual ,,, 
// for android and ios ask permissiot .. if permission error open goto setting alert and redirect them to setting

export class LocationFlowService {
    googlePlaceService: any;

    locationpermissionError: boolean = false;
    platformtype;
    selectedlocation: any;
    selectedCity: any;
    selectedCountry: any;
    selectedLatitude: any;
    selectedLongitude: any;
    isCurrentLocationSet: boolean = false;
    locationsettingModal;
    googleGeocoder;
    displaylocation;
    settingbtn: any;
    settingmsg: any;
    constructor(public platform: Platform,
        public commonFunctions: CommonFunctionsService,
        public storageService: StorageService,
        public alertController: AlertController,
        public modalCtrl: ModalController,) {
        this.googlePlaceService = new google.maps.places.PlacesService(document.createElement('div'));
        this.googleGeocoder = new google.maps.Geocoder();
        this.displaylocation = this.storageService.getLocationStorage("selectedCity") + ", " + this.storageService.getLocationStorage("selectedCountry");
        this.settingbtn = 'Go To Settings';
        this.settingmsg = "It looks like you have turned off permissions required for this feature. It can be enabled under Phone Setiings > Apps > Healable > Permissions";

    }

    checkPlatformTypeLocation() {

        if (this.platform.platforms().some(v => v == "ios")) {
            this.settingbtn = 'Settings';
            this.settingmsg = "It looks like you have turned off permissions required for this feature. It can be enabled under Setiings";

            this.platformtype = "ios";
        }
        else if (this.platform.platforms().some(v => v == "android")) {
            this.platformtype = "android";
            this.settingbtn = 'Go To Settings';
            this.settingmsg = "It looks like you have turned off permissions required for this feature. It can be enabled under Phone Setiings > Apps > Helable > Permissions";

        }
        else {
            this.platformtype = "web";
        }

        this.doaction(this.platformtype)

    }

    doaction(platformtype) {
        console.log("platformtypeplatformtypeplatformtype", this.platformtype)
        this.locationpermissionError = false;

        switch (platformtype) {
            case 'web':
                this.checkForPermission();
                break;

            case 'android':
                this.checkForPermission();
                break;

            case 'ios':
                break;

            default:
                break;
        }
    }

    async checkForPermission() {

        // console.log("permissionpermissionpermission")
        // console.log(this.platform.platforms())

        await Geolocation.checkPermissions().then(async permission => {
            console.log("permissionpermissionpermission", permission)

            if (permission.location === 'granted') {
                // Permission already granted, proceed with getting the location
                this.getLatLong();
            } else if (permission.location === 'prompt') {
                // Permission not granted but not denied, request permission
                if (this.platformtype === "web") {
                    this.getCurrentLocationWithWeb()
                }
                else {
                    this.askForPermission();
                }
            } else {
                // Permission denied, show appropriate message to the userx`
                console.log('Location permission denied');
                this.apppermissionAlert();
                this.locationpermissionError = true;
                // this.getLatLong();
            }
        },
            error => {
                console.log("Permission error ", error);
                // message: 'Location services are not enabled'}
                this.locationpermissionError = true;
                if (error.message === 'Location services are not enabled') {
                    this.commonFunctions.showErrorReporAlert("Please enable device location for accurate result");
                }
            });


    }


    async getCurrentLocationWithWeb() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    console.log('Current Position web:', position);
                    console.log('Current Position web:', position.coords.latitude, position.coords.longitude);
                    this.getcurrentLocation(position.coords.latitude, position.coords.longitude)

                },
                error => {
                    this.locationpermissionError = true;
                    console.log('Error getting location:', error);
                }
            );
        } else {
            this.locationpermissionError = true;
            console.log('Geolocation is not supported by this browser.');
        }
    }

    async askForPermission() {

        console.log("permissionRequestpermissionRequestpermissionRequest")
        const permissionRequest = await Geolocation.requestPermissions();
        console.log("permissionRequestpermissionRequestpermissionRequest", permissionRequest)

        if (permissionRequest.location === 'granted') {
            // Permission granted, proceed with getting the location
            this.getLatLong();
        } else {
            // Permission denied, show appropriate message to the user
            console.log('Location permission denied');
            this.apppermissionAlert();
        }
    }

    async getLatLong() {

        const coordinates = await Geolocation.getCurrentPosition()
        console.log('Current position:', coordinates);
        this.getcurrentLocation(coordinates.coords.latitude, coordinates.coords.longitude)
    }

    async openlocationpage() {
        try {
            this.locationsettingModal = await this.modalCtrl.create({
                cssClass: 'show-all-categories-modal',
                component: LocationPage,
                breakpoints: [0, 0.7],
                handle: false,
                initialBreakpoint: 0.7,
                componentProps: {
                    action: "edit",
                }
            });
            this.locationsettingModal.present();
            const { data, role } = await this.locationsettingModal.onWillDismiss();

            if (role === 'confirm') {
                //  this.allowjoinnowstatus = false;
            }
        } catch (error) {

        }
    }

    async getManualLocation(locationstring, place_id) {
        await this.commonFunctions.showLoading("Fetching location details ...")
        await this.googlePlaceService.getDetails({ placeId: place_id }, async (place, status) => {
            //  console.log(place)
            //  var user_city = place.address_components.filter(ac=>~ac.types.indexOf('locality'))[0].long_name
            //  console.log(user_city)
            //  var user_country = place.address_components.filter(ac=>~ac.types.indexOf("country"))[0].long_name
            //  console.log(user_country)
            //  address.structured_formatting.secondary_text.split(',');
            if (status === google.maps.places.PlacesServiceStatus.OK && place.geometry) {
                this.selectedlocation = locationstring;
                this.selectedLatitude = place.geometry.location.lat();
                this.selectedLongitude = place.geometry.location.lng();
                this.selectedCity = place.address_components.filter(ac => ~ac.types.indexOf('locality'))[0].long_name;
                this.selectedCountry = place.address_components.filter(ac => ~ac.types.indexOf("country"))[0].long_name;
                this.storageService.setLocationStorage("selectedlocation", this.selectedlocation)
                this.storageService.setLocationStorage("selectedCity", this.selectedCity)
                this.storageService.setLocationStorage("selectedCountry", this.selectedCountry)
                this.storageService.setLocationStorage("selectedLatitude", this.selectedLatitude)
                this.storageService.setLocationStorage("selectedLongitude", this.selectedLongitude)
                this.displaylocation = this.selectedCity + ", " + this.selectedCountry;

                this.locationsettingModal.dismiss();
                await this.commonFunctions.hideLoading();
                setTimeout(() => {
                    // this.cdr.detectChanges();
                }, 5000)


            }
        });
    }

    async getcurrentLocation(lat, long) {
        await this.commonFunctions.showLoading("Fetching location details ...")
        // await this.googleGeocoder.geocode({ lat : lat ,  long: long}, (place, status) => {
        //   var geocoder = 
        await this.googleGeocoder.geocode({ 'location': { lat: lat, lng: long } }, async (place, status) => {
            console.log(place[0])
            if (status === 'OK') {
                if (place[0]) {
                    //     console.log( place[0].formatted_address)
                    //     var user_city = place[0].address_components.filter(ac=>~ac.types.indexOf('locality'))[0].long_name
                    //  console.log(user_city)
                    //     var user_country = place[0].address_components.filter(ac=>~ac.types.indexOf("country"))[0].long_name
                    //  console.log(user_country)
                    this.selectedlocation = place[0].formatted_address;
                    this.selectedLatitude = lat;
                    this.selectedLongitude = long;
                    this.selectedCity = place[0].address_components.filter(ac => ~ac.types.indexOf('locality'))[0].long_name;
                    this.selectedCountry = place[0].address_components.filter(ac => ~ac.types.indexOf("country"))[0].long_name;
                    this.storageService.setLocationStorage("selectedlocation", this.selectedlocation)
                    this.storageService.setLocationStorage("selectedLatitude", this.selectedLatitude)
                    this.storageService.setLocationStorage("selectedLongitude", this.selectedLongitude)
                    this.storageService.setLocationStorage("selectedCity", this.selectedCity)
                    this.storageService.setLocationStorage("selectedCountry", this.selectedCountry)
                    this.displaylocation = this.selectedCity + ", " + this.selectedCountry;

                    this.locationsettingModal.dismiss();
                    await this.commonFunctions.hideLoading();
                    setTimeout(() => {
                        // this.cdr.detectChanges();
                    }, 5000)
                }
            }
        });
    }

    async apppermissionAlert() {
        if (this.platformtype != "web") {
            const alert = await this.alertController.create({
                header: '',
                cssClass: 'apppermissionAlert',
                message: this.settingmsg,
                mode: 'md',
                buttons: [
                    {
                        text: this.settingbtn,
                        handler: () => {
                            // open settings
                            localStorage.setItem("application_details_open", "no");
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })

                        }
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: async (blah) => {
                            console.log('Confirm Cancel: blah');
                            await this.commonFunctions.showErrorReporAlert("Please mark your address manually on the Map to allow accurate delivery");
                            //here take a value from the address list because location turn on cancel
                        }
                    }
                ]
            });
            await alert.present();
        }
    }




}
