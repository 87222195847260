// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.announcement-wrapper {
  padding: 5rem 1rem;
}

.title-wrapper,
.message-wrapper {
  margin-bottom: 1rem;
}

.announcement-textarea {
  border: 1px solid gray;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.close {
  font-size: 30px;
  transform: rotate(45deg);
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  /* border: 1px solid; */
  z-index: 100;
  border-radius: 50%;
  padding: 0.5rem;
}

.close:hover {
  background-color: var(--ion-color-custom-accent-30);
}`, "",{"version":3,"sources":["webpack://./src/app/components/add-announcement/add-announcement.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;;EAEI,mBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;AACJ;;AAEA;EACI,eAAA;EACA,wBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,mDAAA;AACJ","sourcesContent":[".announcement-wrapper {\n    padding: 5rem 1rem;\n}\n\n.title-wrapper,\n.message-wrapper {\n    margin-bottom: 1rem;\n}\n\n.announcement-textarea {\n    border: 1px solid gray;\n    border-radius: 10px;\n    margin-bottom: 1rem;\n    padding: 1rem;\n}\n\n.close {\n    font-size: 30px;\n    transform: rotate(45deg);\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n    cursor: pointer;\n    /* border: 1px solid; */\n    z-index: 100;\n    border-radius: 50%;\n    padding: 0.5rem;\n}\n\n.close:hover {\n    background-color: var(--ion-color-custom-accent-30);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
