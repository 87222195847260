import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ShowAllCategoriesComponent } from '../show-all-categories/show-all-categories.component';

@Component({
    selector: 'app-categories-section',
    templateUrl: './categories-section.component.html',
    styleUrls: ['./categories-section.component.scss'],
})
export class CategoriesSectionComponent implements OnInit {


    @Input('fromHome') fromHome;
    constructor(
        public navigationService: NavigationService,
        public modalCtrl: ModalController
    ) { }

    ngOnInit() { }

    async presentShowAllCategoriesModal(action) {
        try {

            const showAllCategoriesModal = await this.modalCtrl.create({
                cssClass: 'show-all-categories-modal',
                component: ShowAllCategoriesComponent,
                componentProps: {
                    action: action
                }
            });
            showAllCategoriesModal.present();
        } catch (error) {

        }
    }

}
