// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-tab-bar {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

@media screen and (min-width: 970px) {
  .show-tab-bar {
    display: none;
  }
  ion-router-outlet {
    margin-top: 0rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;AACJ;;AAMA;EACI;IACI,aAAA;EAHN;EAKE;IACI,gBAAA;EAHN;AACF","sourcesContent":["ion-tab-bar {\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    left: 0;\n}\n\n// ion-router-outlet {\n//     margin-top: 3rem;\n// }\n\n@media screen and (min-width: 970px) {\n    .show-tab-bar {\n        display: none;\n    }\n    ion-router-outlet {\n        margin-top: 0rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
