import { Component, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ProgramService } from 'src/app/services/program/program.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-show-enrollment',
  templateUrl: './show-enrollment.component.html',
  styleUrls: ['./show-enrollment.component.scss'],
})

export class ShowEnrollmentComponent implements OnInit {

  program_id : any;
  programEnrollees : any = [];
  loadingList : boolean = false;
  constructor( public userService: UserService,
               public alertController: AlertController,
               public modalCtrl: ModalController,
               public navigationService: NavigationService,
               public programService: ProgramService,) { 

               }

  ngOnInit() {
      console.log("program_idprogram_idprogram_idprogram_id",this.program_id)
      this.getlist();
  }


  closeModal() {
    this.modalCtrl.dismiss()
  }

  getlist(){
    try {
      this.loadingList = true;
      this.programService.getProgramEnrollmentList(this.program_id).subscribe((res: any) => {
          //  console.log(res.data)
          this.loadingList = false;
           if (res && res.success == true) {
            this.programEnrollees = res.data[0].programEnrollees;
            console.log(  this.programEnrollees)
           }
      }, (error) => {
        this.loadingList = false;

          console.log("🚀 ~ file: show-all-categories.component.ts:23 ~ ShowAllCategoriesComponent ~ this.categoriesService.getCategories ~ error:", error)
      })
  } catch (error) {
      console.log("🚀 ~ file: show-all-categories.component.ts:26 ~ ShowAllCategoriesComponent ~ getCategories ~ error:", error)
  }
  }
}
