// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon.close.ios.ion-color.ion-color-primary.hydrated {
  font-size: 30px;
  transform: rotate(45deg);
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  /* border: 1px solid; */
  z-index: 100;
  border-radius: 50%;
  padding: 0.5rem;
}

ion-icon.close.ios.ion-color.ion-color-primary.hydrated:hover {
  background-color: var(--ion-color-custom-accent-30);
}

.wrapper {
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.category-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  border: 1px solid var(--ion-color-primary);
  padding: 1rem;
  border-radius: 1rem;
  flex-grow: 1;
}

ion-icon.category-icon.ios.hydrated {
  font-size: xx-large;
  color: var(--ion-color-primary);
}

.category-name {
  color: var(--ion-color-primary);
  font-weight: 700;
}

.section-header-label {
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/show-all-categories/show-all-categories.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,wBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,mDAAA;AACJ;;AAEA;EACI,aAAA;EACA,aAAA;EACA,eAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,0CAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AACJ;;AAEA;EACI,mBAAA;EACA,+BAAA;AACJ;;AAEA;EACI,+BAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AACJ","sourcesContent":["ion-icon.close.ios.ion-color.ion-color-primary.hydrated {\n    font-size: 30px;\n    transform: rotate(45deg);\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n    cursor: pointer;\n    /* border: 1px solid; */\n    z-index: 100;\n    border-radius: 50%;\n    padding: 0.5rem;\n}\n\nion-icon.close.ios.ion-color.ion-color-primary.hydrated:hover {\n    background-color: var(--ion-color-custom-accent-30);\n}\n\n.wrapper {\n    padding: 3rem;\n    display: flex;\n    flex-wrap: wrap;\n    gap: 1rem;\n}\n\n.category-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: max-content;\n    border: 1px solid var(--ion-color-primary);\n    padding: 1rem;\n    border-radius: 1rem;\n    flex-grow: 1;\n}\n\nion-icon.category-icon.ios.hydrated {\n    font-size: xx-large;\n    color: var(--ion-color-primary);\n}\n\n.category-name {\n    color: var(--ion-color-primary);\n    font-weight: 700;\n}\n\n.section-header-label {\n    font-weight: 700;\n    font-size: 24px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 6px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
