import { Component, Input, OnInit } from '@angular/core';
import { IonLabel } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-home-wall-provider',
  templateUrl: './home-wall-provider.component.html',
  styleUrls: ['./home-wall-provider.component.scss'],
})
export class HomeWallProviderComponent implements OnInit {
   
    @Input('sectionLabel') sectionLabel;
    @Input('sectionSubLabel') sectionSubLabel;
    @Input('programsList') programsList;
    constructor(
        public navigationService: NavigationService,
        public userService: UserService
    ) {
    }

    ngOnInit() {
        console.log("this.sectionLabel", this.sectionLabel, "this.sectionSubLabel", this.sectionSubLabel, "this.programsList", this.programsList)
    }

}
