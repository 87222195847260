// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  white-space: nowrap;
}

hr {
  height: 1px;
  background: lightgray;
  border-width: 0;
  margin-top: 5px;
  box-sizing: content-box;
}`, "",{"version":3,"sources":["webpack://./src/app/components/location-results/location-results.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,qBAAA;EACA,eAAA;EACA,eAAA;EACA,uBAAA;AACJ","sourcesContent":[".location {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    padding: 8px;\n    white-space: nowrap;\n}\n\nhr {\n    height: 1px;\n    background: lightgray;\n    border-width: 0;\n    margin-top: 5px;\n    box-sizing: content-box;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
