import { Injectable } from '@angular/core';
import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications';
import { SocketService } from '../socket/socket.service';
import { FCM } from '@capacitor-community/fcm';

@Injectable({
    providedIn: 'root'
})
export class FcmService {

    constructor(public socketService: SocketService) { }

    public initializePushNotifService() {
        this.registerPush()
    }

    private registerPush() {
        PushNotifications.requestPermissions().then((permission) => {
            // alert("permission from user "+ permission)
            if (permission.receive == 'granted') {
                //  alert("permission granted "+ permission.receive)
                PushNotifications.register()
            }
            else {
                // alert("Either notifications permission is not granted or something went wrong " + permission)
            }
        })


        //SETUP NOTIFICATIONS LISTENERS :: start ::

        //listen when token is registered
        PushNotifications.addListener('registration', (token: Token) => {

            FCM.getToken().then((result) => {
                console.log(result.token); // This is token for IOS                    
             
                this.socketService.currentUserDeviceToken = result.token
            }).catch((err) => console.log('i am Error', err));

           
        });

        PushNotifications.addListener('registrationError', (error: any) => {
            //  alert('Error on registration: ' + JSON.stringify(error));
        });

    }








}
