import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService } from '../search/search.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(
        public router: Router,
        public location: Location,
        public searchService: SearchService
    ) { }

    goBack() {
        this.location.back();
    }

    toWelcome() {
        this.router.navigate(['/welcome'])
    }

    toRegistration() {
        this.router.navigate(['/registration'])
    }

    toLogin() {
        this.router.navigate(['/login'])
    }

    toAboutUs() {
        this.router.navigate(['/aboutus'])
    }

    toPrivacyPolicy() {
        this.router.navigate(['/privacy-policy'])
    }

    toTermsAndConditions() {
        this.router.navigate(['/terms-and-conditions'])
    }

    toVerifyCode() {
        this.router.navigate(['/verify-code'])
    }

    toForgotPassword() {
        this.router.navigate(['/forgot-password'])
    }

    toResetPassword() {
        this.router.navigate(['/reset-password'])
    }

    toHome(userObj?) {
        this.router.navigate(['/tabs/home'], { state: userObj })
    }

    toCategories() {
        this.router.navigate(['/categories'])
    }

    toAccount() {
        this.router.navigate(['/account'])
    }

    toMessages() {
        this.router.navigate(['/messages'])
    }

    toPrograms() {
        this.router.navigate(['/programs'])
    }

    toNotification() {
        this.router.navigate(['/notification'])
    }

    toCreateProgram() {
        this.router.navigate(['/create-program'])
    }

    toEditProgram(programObj) {
        this.router.navigate(['/create-program'], { state: programObj })
    }

    toSearch() {
        this.router.navigate(['/search', this.searchService.searchString])
    }

    toSearchProgramsList() {
        this.router.navigate(['/search-programs-list'])
    }

    toSecurity() {
        this.router.navigate(['/security'])
    }

    toBanking() {
        this.router.navigate(['/banking'])
    }

    toPaymentHistory() {
        this.router.navigate(['/payment-history'])
    }

    toUserProfile() {
        this.router.navigate(['/user-profile'])
    }

    toDocuments() {
        this.router.navigate(['/documents'])
    }

    toMyReviews() {
        this.router.navigate(['/my-reviews'])
    }

    toMessageUser(programDetails, chatRoomId, providerId, author_name) {
        let obj = {
            programDetails: programDetails,
            providerId: providerId,
            chatRoomId: chatRoomId,
            author_name: author_name
        }
        console.log("🚀 ~ file: navigation.service.ts:127 ~ NavigationService ~ toMessageUser ~ obj:", obj)
        this.router.navigate(['/message-user'], { state: obj })
    }

    toMyEnrollments(programType) {
        this.router.navigate(['/my-enrollments', { programType: programType }])
    }

    toProgramDetails(id) {
        this.router.navigate(['/program-details', id])
    }

    toProgramDrafts() {
        this.router.navigate(['/program-drafts'])
    }

    toCreateProvider() {
        this.router.navigate(['/create-provider'])
    }

    toProviderInfo() {
        this.router.navigate(['/user-profile-provider'])
    }

    toPublicProfile(id) {
        this.router.navigate(['/user-profile-public', id])
    }

    toReports() {
        this.router.navigate(['/reports'])
    }

    toReferral() {
        this.router.navigate(['/referral'])
    }

    toSupport() {
        this.router.navigate(['/support'])
    }

}
