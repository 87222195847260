import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-community-guidelines',
    templateUrl: './community-guidelines.component.html',
    styleUrls: ['./community-guidelines.component.scss'],
})
export class CommunityGuidelinesComponent implements OnInit {

    constructor(
        public modalController: ModalController
    ) { }

    ngOnInit() { }

    dismiss(val) {
        this.modalController.dismiss({ val: val })
    }

}
