import { Component, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../services/user/user.service';
import { AlertController } from '@ionic/angular';

@Component({
    selector: 'app-tabs',
    templateUrl: 'tabs.page.html',
    styleUrls: ['tabs.page.scss']
})

@Injectable({
    providedIn: 'root'
})

export class TabsPage {

    tab1Active: boolean = true;
    tab2Active: boolean = false;
    tab3Active: boolean = false;
    tab4Active: boolean = false;
    tab5Active: boolean = false;
    // tab5: string = "createProvider";
    activeTabName: string = 'home';
    tab5: string = this.activeTabName;
    shareTalentValue: string = 'shareTalent';



    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public userService: UserService,
        public alertController: AlertController
    ) {
        // if (this.userService.currentUser.is_service_provider == 'true' || this.userService.currentUser.is_service_provider == true) this.tab5 = "shareTalent"
        // else if (this.userService.currentUser.is_service_provider == 'false' || this.userService.currentUser.is_service_provider == false) this.tab5 = "createProvider"
        if (this.userService.currentUser.is_service_provider == 'true' || this.userService.currentUser.is_service_provider == true) this.shareTalentValue = "shareTalent"
        else if (this.userService.currentUser.is_service_provider == 'false' || this.userService.currentUser.is_service_provider == false) this.shareTalentValue = "createProvider"
        console.log("🚀 ~ file: tabs.page.ts:42 ~ TabsPage ~ this.shareTalentValue:", this.shareTalentValue)
    }

    ngAfterViewInit() {
        this.route
        console.log("🚀 ~ file: tabs.page.ts:26 ~ TabsPage ~ ngAfterViewInit ~ this.location.path():", this.location.path())
        this.checkActiveUrlAndSetActiveTab()
        console.log("🚀 ~ file: tabs.page.ts:43 ~ TabsPage ~ ngAfterViewInit ~ this.route:", this.route)
    }

    showAlert() {
        if (this.userService.currentUser.is_service_provider == 'pending') this.presentAlertProfileUnderReview();
    }

    getLocation() {
        console.log("🚀 ~ file: tabs.page.ts:52 ~ TabsPage ~ getLocation ~ window.location.pathname:", window.location.pathname)
    }

    checkActiveUrlAndSetActiveTab() {
        if (this.location.path().includes('home')) this.tabActive('tab1');
        else if (this.location.path().includes('programs')) this.tabActive('tab2');
        else if (this.location.path().includes('account')) this.tabActive('tab4');
        else if (this.location.path().includes('shareTalent') || this.location.path().includes('createProvider')) this.tabActive('tab5');
    }

    async presentAlertProfileUnderReview() {
        const alertForProfileUnderReview = this.alertController.create({
            message: "Looks like you've already submitted your profile, which is great! It's currently under review and we should have an update for you within 48 hours. Thanks for your patience, and we'll get back to you soon!",
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                    handler: () => { }
                }
            ]
        });
        (await alertForProfileUnderReview).present();
    }

    tabActive(activeTab) {

        console.log("tabActive called", activeTab);

        switch (activeTab) {

            case 'tab1':
                this.tab1Active = true;
                this.tab2Active = false;
                this.tab3Active = false;
                this.tab4Active = false;
                this.tab5Active = false;
                this.activeTabName = 'home'
                // this.tab5 = this.activeTabName;
                break;

            case 'tab2':
                this.tab1Active = false;
                this.tab2Active = true;
                this.tab3Active = false;
                this.tab4Active = false;
                this.tab5Active = false;
                this.activeTabName = 'programs'
                // this.tab5 = this.activeTabName;
                break;

            case 'tab3':
                this.tab1Active = false;
                this.tab2Active = false;
                this.tab3Active = true;
                this.tab4Active = false;
                this.tab5Active = false;
                break;

            case 'tab4':
                this.tab1Active = false;
                this.tab2Active = false;
                this.tab3Active = false;
                this.tab4Active = true;
                this.tab5Active = false;
                this.activeTabName = 'account'
                // this.tab5 = this.activeTabName;
                break;

            case 'tab5':
                this.tab1Active = false;
                this.tab2Active = false;
                this.tab3Active = false;
                this.tab4Active = false;
                this.tab5Active = true;
                break;
        }

        console.log("🚀 ~ file: tabs.page.ts:84 ~ TabsPage ~ tabActive ~ this.tab1Active:", this.tab1Active)
        console.log("🚀 ~ file: tabs.page.ts:86 ~ TabsPage ~ tabActive ~ this.tab2Active:", this.tab2Active)
        console.log("🚀 ~ file: tabs.page.ts:92 ~ TabsPage ~ tabActive ~ this.tab5Active:", this.tab5Active)
        console.log("🚀 ~ file: tabs.page.ts:88 ~ TabsPage ~ tabActive ~ this.tab3Active:", this.tab3Active)
        console.log("🚀 ~ file: tabs.page.ts:90 ~ TabsPage ~ tabActive ~ this.tab4Active:", this.tab4Active)
    }

}