// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option-wrapper {
  display: flex;
  flex-direction: column;
}

hr {
  width: 100%;
  background: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/components/my-review-options/my-review-options.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,WAAA;EACA,oCAAA;AACJ","sourcesContent":[".option-wrapper {\n    display: flex;\n    flex-direction: column;\n}\n\nhr {\n    width: 100%;\n    background: var(--ion-color-primary);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
