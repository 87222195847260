import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage/storage.service';
import { CommonFunctionsService } from '../common/common-functions.service';
import { ChatService } from '../chat/chat.service';
import { NotificationsService } from '../notifications/notifications.service';
// import {}

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    baseServerUrl: any = environment.API_URL
    loggedInUserId: any = ""
    socket: Socket
    currentUserDeviceType: any = ""
    currentUserDeviceToken: any = ""
    activeChatRommId: any = ""
    isUserInMessagesPage: boolean = false
    constructor(
        private http: HttpClient,
        private StorageService: StorageService,
        public commonFunctions: CommonFunctionsService,
        public chatService: ChatService,
        public notificationService: NotificationsService
    ) { }


    initSocket() {
        console.log("base server url", this.baseServerUrl);

        if (this.socket && this.socket.connected) {
            console.log("Socket is already connected");
            return

        }
        const self = this
        self.loggedInUserId = JSON.parse(self.StorageService.getStorage('currentUser'))._id


        self.currentUserDeviceType = self.commonFunctions.checkPlatform()
        this.socket = io(self.baseServerUrl, { query: { userId: self.loggedInUserId, deviceType: self.currentUserDeviceType, deviceToken: self.currentUserDeviceToken } })

        this.socket.on('connect', () => {
            console.log("socket connected to the server.");
        })

        this.socket.on('notification', (notifObject) => {
            this.notificationService.newNotificationRecieved = true;
            console.log("🚀 ~ file: socket.service.ts:49 ~ SocketService ~ this.socket.on ~ notifObject:", notifObject)
        })


        this.socket.on('new-message', (notifObject) => {
            if (notifObject.chatMessageDetails.roomId == this.activeChatRommId) {
                console.log("🚀 ~ file: socket.service.ts:54 ~ SocketService ~ this.socket.on ~ notifObject:", notifObject)
                this.chatService.activeChatRoomMessages.push(notifObject.chatMessageDetails)
            }
            else if (this.isUserInMessagesPage == true) {
                this.chatService.getChatRoomList()
            }

        })
    }

    logout() {
        if (this.socket) {
            this.socket.emit('logout')
            console.log('Disconnected from the server');
        }
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            console.log('Disconnected from the server');
        }
    }


}






