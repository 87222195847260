import { Component } from '@angular/core';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { ReviewsService } from 'src/app/services/reviews/reviews.service';
import { AddReviewComponent } from '../add-review/add-review.component';
import { StorageService } from 'src/app/services/storage/storage.service';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { ProgramService } from 'src/app/services/program/program.service';
@Component({
    selector: 'app-show-all-reviews',
    templateUrl: './show-all-reviews.component.html',
    styleUrls: ['./show-all-reviews.component.scss']
})
export class ShowAllReviewsComponent {
    reviews: any = []
    title;
    programDetails: any = '';
    current_login_id ;
    constructor(
        public modalCtrl: ModalController,
        public storageService: StorageService,
        public actionSheetCtrl: ActionSheetController,
        public alertController: AlertController,
        public reviewsService: ReviewsService,
        public route: ActivatedRoute,
        public programService: ProgramService
    ) { }

    ngOnInit() { 
        this.current_login_id = JSON.parse(this.storageService.getStorage('currentUser'))._id;
      console.log("Show all reviews console: ",this.reviews)
 }

 async alertConfirmDeleteReview(review) {
        const alert = this.alertController.create({
            message: 'Are you sure you want to delete review?',
            buttons: [
                {
                    text: 'No, cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes, delete',
                    handler: () => {
                        this.removeReview(review)
                    }
                }
            ]
        });
        (await alert).present();
    }

    async presentOptionsActionSheet(review) {
        console.log("🚀 ~ file: show-all-reviews.component.ts:44 ~ ShowAllReviewsComponent ~ presentOptionsActionSheet ~ review:", review)
        const actionSheet = await this.actionSheetCtrl.create({
            
            buttons: [
                {
                    text: 'Delete',
                    role: 'destructive',
                    data: {
                        action: 'delete',
                    },
                    handler: () => { this.alertConfirmDeleteReview(review) }
                },
                {
                    text: 'Edit',
                    data: {
                        action: 'share',
                    },
                    handler: () => { this.editReview(review) }
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    data: {
                        action: 'cancel',
                    },
                },
            ],
        });

        await actionSheet.present();

        const result = await actionSheet.onDidDismiss();
    }

    async alertReviewDeletedSuccessfully() {
        const alert = this.alertController.create({
            message: 'Review deleted successfully!!',
            buttons: [
                {
                    text: 'ok',
                    role: 'cancel',
                    handler: () => { this.closeModal() }
                }
            ]
        });
        (await alert).present();
    }

    async openAddReviewModal(review) {
        try {
            const addReviewModal = await this.modalCtrl.create({
                cssClass: 'add-review-modal',
                component: AddReviewComponent,
                componentProps: {
                    review: review,
                    programId: review.program_id,
                    instructor_id: review.instructor_id,
                    program_name: review.program_name
                }
            });
            addReviewModal.present();
        } catch (error) {

        }
    }

    removeReview(review) {
        let updateObj = {
            'disable': true,
            updatedAt: new Date(),
        }
        this.reviewsService.UpdateReview(review._id, updateObj).subscribe((res: any) => {
            if (res && res.success == true) { this.alertReviewDeletedSuccessfully()
                this.reviewsService.getAvgStarRatingFunction(review.program_id);

            };
        })
    }

    editReview(review) {
        this.openAddReviewModal(review);
    }

    closeModal() {
        this.modalCtrl.dismiss()
    }

    displayInitialName(name){
        return name.slice(0,1)
    }

}
