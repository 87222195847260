import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { StripeCardNumberComponent, STRIPE_OPTIONS, StripeService } from 'ngx-stripe';
import { ProgramService } from 'src/app/services/program/program.service';
import { UserService } from 'src/app/services/user/user.service';
import {
    StripeCardElementOptions,
    StripeElementsOptions,
    PaymentIntent,
} from '@stripe/stripe-js';
import { catchError, tap } from 'rxjs';
import { Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { ModalController } from '@ionic/angular';
import { MyConfig } from 'src/assets/config/app-config';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
})
export class PaymentFormComponent implements OnInit {
  ammount ;
  full_name ;
  email ;
  title ;
  programId ;
  authorId;
  processstarted : boolean = false;
  submitAttemptpayment : boolean = false;
  cardnumberError : boolean = false;
  cardnumberempty : boolean = true;
  cardnumberErrorMsg : any = '';

  cardexpiryError : boolean = false;
  cardexpiryempty : boolean = true;
  cardexpiryErrorMsg : any = '';

  cardcvvempty : boolean = true;
  cardcvvError : boolean = false;
  cardcvvErrorMsg : any = '';
  myconfig: any = MyConfig;

  processicon : boolean = false;
  processdoneicon : boolean = false;

  cardimg : any = "default";
  paymentReport : any = "";
    @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
    public cardOptions: StripeCardElementOptions = {
        style: {
            base: {
              fontWeight: 400,
              fontSize: '16px',
              iconColor: '#666EE8',
              color: '#002333',
              '::placeholder': {
                color: '#919191',
              },
            },
        },
    };

    public elementsOptions: StripeElementsOptions = {
        locale: 'en',
    };

    paymentForm: FormGroup;
    payment_status: string =  '';

  constructor(public userService: UserService,
        public alertController: AlertController,
        public programService: ProgramService,
        public modalCtrl: ModalController,
        private http: HttpClient,
        private fb: FormBuilder,
        private stripeService: StripeService,
        private paymentService: PaymentService) { }

  ngOnInit() {
    this.payment_status = "";
    this.paymentForm = this.fb.group({
      name: [this.full_name, [ Validators.required]],
      email: [this.email, [ Validators.required]],
      amount: [this.ammount, [ Validators.required]],
  });
  }

  
  closeModal(action) {
    this.modalCtrl.dismiss(this.paymentReport, action)
  }
  
  cardNumberElementComplete(event) {
    // Handle card number element completion
    console.log('Card number element complete:', event);
    if(event.error){
      this.cardnumberError = true;
      this.cardnumberErrorMsg = event.error.message;
    }
    else{
      this.cardnumberError = false;
    }
    this.cardnumberempty = event.empty ? true : false;
    this.cardimg = event.brand;
  }

  cardExpiryElementComplete(event) {
    // Handle card expiry element completion
    console.log('Card expiry element complete:', event);
    if(event.error){
      this.cardexpiryError = true;
      this.cardexpiryErrorMsg = event.error.message;
    }
    else{
      this.cardexpiryError = false;
    }
    this.cardexpiryempty = event.empty ? true : false;

  }

  cardCvcElementComplete(event) {
    // Handle card CVC element completion
    console.log('Card CVC element complete:', event);
    if(event.error){
      this.cardcvvError = true;
      this.cardcvvErrorMsg = event.error.message;
    }
    else{
      this.cardcvvError = false;
    }
    this.cardcvvempty = event.empty ? true : false;

  }

  pay(): void {
    this.submitAttemptpayment = true;
    if (this.paymentForm.valid && !this.cardnumberempty && !this.cardexpiryempty && !this.cardcvvempty) {
      this.processstarted = true;
      this.processicon = true;
      this.payment_status = "Your transaction started ...";
        this.paymentService.createPaymentIntent(this.ammount)
            .subscribe((result:any) => {
                console.log(result);
                this.payment_status = "Your payment is in process";

                this.stripeService.confirmCardPayment(result.clientSecret, {
                    payment_method: {
                        card: this.card.element,
                        billing_details: {
                            name: this.paymentForm.get('name').value,
                        },
                    },
                }).subscribe((result) => {
                    console.log(result);
                    let paymentObject :any
                    if (result.error) {
                      
                      // Show error to your customer (e.g., insufficient funds)
                      console.log(result.error.message);
                      this.payment_status = result.error.message;
                      this.payment_status = "Your payment is cancel";

                      paymentObject = {
                        payer: this.userService.currentUser._id,
                        receiver: this.authorId ,
                        stripePaymentInfo : result.paymentIntent,
                        paymentMadeOn : new Date(),
                        status: 'failed',
                        isRefundRequested : false,
                        programId: this.programId,
                        amount : this.ammount,
                        statusDescription : result.error.message 
                      }

                    

                    } else {
                      // The payment has been processed!
                      if (result.paymentIntent.status === 'succeeded') {
                        paymentObject = {
                          payer: this.userService.currentUser._id,
                          receiver: this.authorId ,
                          stripePaymentInfo : result.paymentIntent,
                          paymentMadeOn : new Date(),
                          status: 'succeeded',
                          isRefundRequested : false,
                          programId: this.programId,
                          amount : this.ammount,
                          statusDescription : "Payment success"
                        }
                        // Show a success message to your customer
                        this.payment_status = result.paymentIntent.status;
                        this.payment_status = "Your payment is success";

                       
                     
                      }
                    }

                    this.paymentService.savePaymentToDatabase(paymentObject).subscribe((data:any)=>{
                      if(data){
                        console.log("payment stored");
                        if(paymentObject.status == "succeeded"){
                          this.closeModal('confirm')
                          this.joinProgram();
                        }
                        else{
                          this.closeModal('cancel')
                        }
                        
                      }
                    })
                  },(err)=>{
                    this.closeModal('cancel')
                  });
                
            },err=>{
              console.log(err)
              this.processstarted = false;
              this.processicon = false;
              this.payment_status = "Payment Failed..";
            });
    } else {
        console.log(this.paymentForm);
        this.cardnumberError = true;
        }
}

joinProgram() {
  let joinProgramObj = {
      programId: this.programId,
      seekerId: this.userService.currentUser._id,
      programAuthorId: this.authorId
  }

  this.programService.joinProgram(joinProgramObj).subscribe((res) => {
      if (res) {
        console.log("success")
        this.payment_status = "Program joined successfully";
        this.processicon = false;
        this.processdoneicon = true;
      
        setTimeout(() => {
          this.closeModal('confirm');
        }, 1500);
      }
      console.log("🚀 ~ file: program-details.page.ts:55 ~ ProgramDetailsPage ~ this.programService.joinProgram ~ res:", res)
  })
}
}
