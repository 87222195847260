import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    baseServerUrl: any = environment.API_URL
    searchString = '';
    searchResults: any = [];
    searchResultsProvider: any = [];
    searchResultsPrograms: any = [];
    isSearchResultListDisplayed: boolean = true;

    constructor(
        private http: HttpClient,
    ) { }

    performSearch(searchString) {
        this.searchResults = [];
        if (searchString != '') {
            this.searchString = searchString;
            this.isSearchResultListDisplayed = true;
            // for (const item of this.testArray) {
            //     if (item.full_name.includes(searchString)) this.searchResults.push(item);
            // }

            this.getSearchResults(searchString).subscribe((res: any) => {
                console.log("🚀 ~ SearchService ~ this.getSearchResults ~ res:", res)
                this.searchResults = res.data;
                this.filterProviderAndProgrames();
            })
        }

        // console.log("🚀 ~ file: search.service.ts:96 ~ SearchService ~ performSearch ~ this.searchResults:", this.searchResults)
    }

    getAllSearchResultForSearchPage(searchString) {
        this.searchResults = [];
        if (searchString != '') {
            this.isSearchResultListDisplayed = false;
            // for (const item of this.testArray) {
            //     if (item.full_name.includes(searchString)) this.searchResults.push(item);
            // }

            this.getAllSearchResults(searchString).subscribe((res: any) => {
                console.log("🚀 ~ SearchService ~ this.getSearchResults ~ res:", res)
                this.searchResults = res.data;
                this.filterProviderAndProgrames();
            })
        }
    }

    filterProviderAndProgrames() {
        this.searchResultsProvider = [];
        this.searchResultsPrograms = [];
        for (let i = 0; i < this.searchResults.length; i++) {
            const elem = this.searchResults[i];
            if (elem.full_name) this.searchResultsProvider.push(elem)
            if (elem.program_title) this.searchResultsPrograms.push(elem)

        }
    }

    hideSearchResultsList() {
        this.isSearchResultListDisplayed = false;
    }

    getSearchResults(searchString) {
        console.log("getSearchResults called");

        return this.http.get(this.baseServerUrl + `/search/getSearchResults?searchString=${searchString}`).pipe(tap((data) => {
        }), catchError((err) => {
            throw (err)
        }))
    }

    getAllSearchResults(searchString) {
        console.log("getAllSearchResults called");

        return this.http.get(this.baseServerUrl + `/search/getAllSearchResults?searchString=${searchString}`).pipe(tap((data) => {
        }), catchError((err) => {
            throw (err)
        }))
    }
}
