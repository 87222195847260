import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ReviewsService } from 'src/app/services/reviews/reviews.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
    selector: 'app-add-review',
    templateUrl: './add-review.component.html',
    styleUrls: ['./add-review.component.scss'],
})
export class AddReviewComponent implements OnInit {
    starRating: any = ['star-outline', 'star-outline', 'star-outline', 'star-outline', 'star-outline'];
    reviewMessage: any = '';
    AuthorId: any = '';
    programId;
    instructor_id;
    review;
    program_name;
    submitAttempt: boolean = false;
    isEditReview: boolean = false;
    constructor(
        public alertController: AlertController,
        public modalCtrl: ModalController,
        public storageService: StorageService,
        public reviewsService: ReviewsService
    ) { }

    ngOnInit() {
        this.AuthorId = JSON.parse(this.storageService.getStorage('currentUser'))._id
        this.checkIfEditingReview();
    }

    checkIfEditingReview() {
        if (this.review != undefined && this.review != '' && this.review != null) {
            this.isEditReview = true;
            this.reviewMessage = this.review.review_message;
            this.starRating = this.review.star_rating;
        }
    }

    assignStarRatingTitle(rating) {
        switch (rating) {
            case '1':
                this.starRating = ['star', 'star-outline', 'star-outline', 'star-outline', 'star-outline']
                break;

            case '2':
                this.starRating = ['star', 'star', 'star-outline', 'star-outline', 'star-outline']
                break;

            case '3':
                this.starRating = ['star', 'star', 'star', 'star-outline', 'star-outline']
                break;

            case '4':
                this.starRating = ['star', 'star', 'star', 'star', 'star-outline']
                break;

            case '5':
                this.starRating = ['star', 'star', 'star', 'star', 'star']
                break;

            default:
                this.starRating = ['star-outline', 'star-outline', 'star-outline', 'star-outline', 'star-outline'];
                break;
        }
    }

    assignReviewMessage(e) {
        this.reviewMessage = e.target.value;
    }

    checkIfStarRatingFilled() {
        return this.starRating.includes('star');
    }

    checkIfReviewMessageFilled() {
        return this.reviewMessage == '' ? false : true
    }

    checkIfTextFilledBeforeAddReview() {
        if (this.checkIfStarRatingFilled() && this.checkIfReviewMessageFilled() && this.isEditReview == false) this.alertConfirmPostReview();
        if (this.checkIfStarRatingFilled() && this.checkIfReviewMessageFilled() && this.isEditReview == true) this.alertConfirmEditPublishReview();
        else this.submitAttempt = true;
    }

    checkIfTextEmptyBeforeCloseModal() {
        if (!this.checkIfReviewMessageFilled() && !this.checkIfStarRatingFilled()) this.closeModal();
        else this.alertConfirmCloseModal();
    }

    addReview() {
        let publishObj = {
            createdAt: new Date(),
            updatedAt: new Date(),
            disable: false,
            author_id: this.AuthorId,
            program_id: this.programId,
            star_rating: this.starRating,
            review_message: this.reviewMessage,
            instructor_id: this.instructor_id,
            program_name: this.program_name
        }

        this.reviewsService.PostReview(publishObj).subscribe((res: any) => {
            if (res && res.success == true) {this.alertReviewPublishedSuccessfully()
                this.reviewsService.getAvgStarRatingFunction(this.programId);

            
            };
        })

    }

    editReview() {
        let publishObj = {
            updatedAt: new Date(),
            star_rating: this.starRating,
            review_message: this.reviewMessage,

             author_id: this.AuthorId,
                 program_id: this.programId,
                 instructor_id: this.instructor_id,
             program_name: this.program_name
        }
        console.log("REVIEW EDIT: ", publishObj)

        this.reviewsService.UpdateReview(this.review._id, publishObj).subscribe((res: any) => {
            if (res && res.success == true) {
                
                this.alertReviewSavedAndPublishedSuccessfully()
                this.reviewsService.getAvgStarRatingFunction(this.programId);

            }
            console.log("🚀 ~ file: add-review.component.ts:68 ~ AddReviewComponent ~ this.reviewsService.PostReviews ~ res:", res)
        })
    }

    async alertReviewPublishedSuccessfully() {
        const alert = this.alertController.create({
            message: 'Review published successfully!!',
            buttons: [
                {
                    text: 'ok',
                    role: 'cancel',
                    handler: () => { this.closeModal() }
                }
            ]
        });
        (await alert).present();
    }

    async alertReviewSavedAndPublishedSuccessfully() {
        const alert = this.alertController.create({
            message: 'Review saved and published successfully!!',
            buttons: [
                {
                    text: 'ok',
                    role: 'cancel',
                    handler: () => { this.closeModal() }
                }
            ]
        });
        (await alert).present();
    }

    async alertConfirmPostReview() {
        const alert = this.alertController.create({
            message: 'Confirm post review',
            buttons: [
                {
                    text: 'No, cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes, publish',
                    handler: () => {
                        this.addReview()
                    }
                }
            ]
        });
        (await alert).present();
    }

    async alertConfirmEditPublishReview() {
        const alert = this.alertController.create({
            message: 'Confirm save and publish review',
            buttons: [
                {
                    text: 'No, cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes, publish',
                    handler: () => {
                        this.editReview()
                    }
                }
            ]
        });
        (await alert).present();
    }

    async alertConfirmCloseModal() {
        const alert = this.alertController.create({
            message: 'are you sure you want to discard the review?',
            buttons: [
                {
                    text: 'No, cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes, discard',
                    handler: () => {
                        this.closeModal()
                    }
                }
            ]
        });
        (await alert).present();
    }


    closeModal() {
        this.modalCtrl.dismiss()
    }
}
