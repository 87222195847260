import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';


const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
        canActivate: [AuthGuard]

    },
    // {
    //     path: '',
    //        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),

    //     // loadChildren: () => import('./pages/intro-slides/intro-slides.module').then(m => m.IntroSlidesPageModule)
    // },
    // {
    //     path: '',
    //     loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
    //     //    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),

    //     // loadChildren: () => import('./pages/intro-slides/intro-slides.module').then(m => m.IntroSlidesPageModule)
    // },
    // {
    //     path: 'tabs',
    //     loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    //     canActivate: [AuthGuard]

    // },
    {
        path: 'intro-slides',
        loadChildren: () => import('./pages/intro-slides/intro-slides.module').then(m => m.IntroSlidesPageModule)
    },
    {
        path: 'welcome',
        loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
    },
    {
        path: 'registration',
        loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
    },
    {
        path: 'terms-and-conditions',
        loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsPageModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
    },
    {
        path: 'verify-code',
        loadChildren: () => import('./pages/verify-code/verify-code.module').then(m => m.VerifyCodePageModule)
    },
    {
        path: 'categories',
        loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'messages',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'programs',
        loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'notification',
        loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'create-program',
        loadChildren: () => import('./pages/create-program/create-program.module').then(m => m.CreateProgramPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'search/:searchString',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'search-programs-list',
        loadChildren: () => import('./pages/search-programs-list/search-programs-list.module').then(m => m.SearchProgramsListPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'security',
        loadChildren: () => import('./pages/security/security.module').then(m => m.SecurityPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'user-profile',
        loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfilePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'documents',
        loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'my-reviews',
        loadChildren: () => import('./pages/my-reviews/my-reviews.module').then(m => m.MyReviewsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'message-user',
        loadChildren: () => import('./pages/message-user/message-user.module').then(m => m.MessageUserPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'my-enrollments',
        loadChildren: () => import('./pages/my-enrollments/my-enrollments.module').then(m => m.MyEnrollmentsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'program-details/:id',
        loadChildren: () => import('./pages/program-details/program-details.module').then(m => m.ProgramDetailsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'program-drafts',
        loadChildren: () => import('./pages/program-drafts/program-drafts.module').then(m => m.ProgramDraftsPageModule)
    },
    {
        path: 'create-provider',
        loadChildren: () => import('./pages/create-provider/create-provider.module').then(m => m.CreateProviderPageModule)
    },
    {
        path: 'banking',
        loadChildren: () => import('./pages/banking/banking.module').then(m => m.BankingPageModule)
    },
    {
        path: 'payment-history',
        loadChildren: () => import('./pages/payment-history/payment-history.module').then(m => m.PaymentHistoryPageModule)
    },
    {
        path: 'user-profile-provider',
        loadChildren: () => import('./pages/user-profile-provider/user-profile-provider.module').then(m => m.UserProfileProviderPageModule)
    },
    {
        path: 'location',
        loadChildren: () => import('./pages/location/location.module').then(m => m.LocationPageModule)
    },
    {
        path: 'user-profile-public/:providerId',
        loadChildren: () => import('./pages/user-profile-public/user-profile-public.module').then(m => m.UserProfilePublicPageModule)
    },
    {
        path: 'aboutus',
        loadChildren: () => import('./pages/aboutus/aboutus.module').then(m => m.AboutusPageModule)
    },
    {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsPageModule)
    },
    {
        path: 'personality-card',
        loadChildren: () => import('./pages/personality-card/personality-card.module').then(m => m.PersonalityCardPageModule)
    },
    {
        path: 'display-image/:id',
        loadChildren: () => import('./pages/display-image/display-image.module').then(m => m.DisplayImagePageModule)
    },
    {
        path: 'referral',
        loadChildren: () => import('./pages/referral/referral.module').then(m => m.ReferralPageModule)
    },
    {
        path: 'support',
        loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule)
    }










];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
