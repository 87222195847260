// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-l-16 {
  padding-left: 16px;
}

.cardType {
  position: absolute;
  right: 25px;
  width: 20px;
  padding-top: 14px;
  zoom: 1.8;
  margin-top: -30px;
}

.paymnt-form {
  padding: 0 30px;
}

.payment-report {
  color: #262626;
}

ion-spinner {
  width: 70px;
  height: 70px;
  margin-top: 10px;
}

.sucessicon {
  font-size: 50px;
  color: #008000;
}

.title {
  color: #000;
  font-family: Inria Serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/components/payment-form/payment-form.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AACA;EACI,kBAAA;EACA,WAAA;EACA,WAAA;EACA,iBAAA;EACA,SAAA;EACA,iBAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;;AADA;EACI,cAAA;AAIJ;;AAFA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;AAKJ;;AAHA;EACI,eAAA;EACA,cAAA;AAMJ;;AAHA;EACI,WAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAMJ","sourcesContent":[".p-l-16{\n    padding-left: 16px;\n}\n.cardType{\n    position: absolute;\n    right: 25px;\n    width: 20px;\n    padding-top: 14px;\n    zoom: 1.8;\n    margin-top: -30px;\n}\n.paymnt-form{\n    padding: 0 30px;\n}\n.payment-report{\n    color : #262626;\n}\nion-spinner{\n    width: 70px;\n    height: 70px;\n    margin-top: 10px;\n}\n.sucessicon{\n    font-size: 50px;\n    color: #008000;\n}\n\n.title {\n    color: #000;\n    font-family: Inria Serif;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
