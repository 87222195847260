import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ProgramService } from 'src/app/services/program/program.service';
import { UserService } from 'src/app/services/user/user.service';
import { catchError, tap } from 'rxjs';
import { Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { MyConfig } from 'src/assets/config/app-config';

@Component({
  selector: 'app-professtional',
  templateUrl: './professtional.component.html',
  styleUrls: ['./professtional.component.scss'],
})
export class ProfesstionalComponent implements OnInit {
  
  myconfig: any = MyConfig;
  profiessionalInfo: FormGroup;

  constructor(
    public modalCtrl: ModalController,
    public userService: UserService,
    public formBuilder: FormBuilder,) { }

  closeModal( ) {
    this.modalCtrl.dismiss()
  }

  ngOnInit() {
    this.initForm();
  }

initForm() {
    try {
        this.profiessionalInfo = this.formBuilder.group({
          user_work_experience : [this.userService.currentUser.user_work_experience,  [  Validators.required]],
        
      }) 
    }
    catch (error) { }
}
saveProfessional(){
  if (this.profiessionalInfo.valid ) {
    // "user_education": [],
    // "user_acheivements": [],
    const updateabout = { 
      user_work_experience:  this.profiessionalInfo.get('user_work_experience').value,  
     };

      this.userService.userUpdate(updateabout)
          .subscribe((result:any) => {
            this.userService.currentUser.user_work_experience = this.profiessionalInfo.get('user_work_experience').value;
            this.userService.showcomontoast(result.message);
            this.closeModal();

          });
        }

 }
 
}
