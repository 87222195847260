import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    baseServerUrl: any = environment.API_URL
    activeChatRoomMessages: any = [];
    chatList : any = [];

    constructor(
        private http: HttpClient,
        public StorageService : StorageService
    ) { }

    createMessage(msgObj) {
        return this.http.patch(this.baseServerUrl + '/chat/send-message', JSON.stringify(msgObj)).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    getChatRoomList() {
         const loggedInUserId = JSON.parse(this.StorageService.getStorage('currentUser'))._id
        this.getChatRooms(loggedInUserId).subscribe(res => {
            this.chatList = res;
            console.log("🚀 ~ file: messages.page.ts:28 ~ MessagesPage ~ this.chatService.getChatRooms ~ res:", res)
        })
    }

    getChatRooms(uid) {
        return this.http.get(this.baseServerUrl + `/chat/get-chat-rooms/${uid}`).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    checkIfChatRoomExists(user1, user2, pid) {
        return this.http.get(this.baseServerUrl + `/chat/check-chat-room-exist/${user1}/${user2}/${pid}`).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    getChatRoomMessages(chatRoomId) {
        return this.http.get(this.baseServerUrl + `/chat/get-all-chat-room-messages/${chatRoomId}`).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }
}
