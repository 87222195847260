import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oauth-login',
  templateUrl: './oauth-login.component.html',
  styleUrls: ['./oauth-login.component.scss'],
})
export class OauthLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
