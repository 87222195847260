import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
    selector: 'app-profile-avatar',
    templateUrl: './profile-avatar.component.html',
    styleUrls: ['./profile-avatar.component.scss'],
})
export class ProfileAvatarComponent implements OnInit {
    firstName: any;
    lastName: any;
    displayUrl: any;
    currentUser: any;

    constructor(
        public userService: UserService,
        public storageService: StorageService,
    ) { }

    ngOnInit() {
        console.log("ProfileAvatarComponent constructor called");
        this.currentUser = JSON.parse(this.storageService.getStorage('currentUser'))
        this.userService.userData$.subscribe(res => {
            console.log("🚀 ~ file: profile-avatar.component.ts:32 ~ ProfileAvatarComponent ~ ngOnInit ~ res:", res)
            if (res) {
                this.firstName = res.first_name[0];
                this.lastName = res.last_name[0];
                if (res.profile_picture.length > 0) this.displayUrl = res.profile_picture[0].displayUrl;
            }
        })
    }

    // ngAfterViewInit() {
    //     console.log("ProfileAvatarComponent ngAfterViewInit called");
    //     if (this.userService.currentUser) {
    //         console.log("🚀 ~ file: profile-avatar.component.ts:25 ~ ProfileAvatarComponent ~ ngAfterViewInit ~ this.userService.currentUser:", this.userService.currentUser)
    //         this.firstName = this.userService?.currentUser?.first_name[0];
    //         this.lastName = this.userService?.currentUser?.last_name[0];
    //         this.displayUrl = this.userService?.currentUser?.profile_picture[0]?.displayUrl
    //     }
    // }

}
