import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-date-display',
    templateUrl: './date-display.component.html',
    styleUrls: ['./date-display.component.scss'],
})
export class DateDisplayComponent implements OnInit {
    @Input('date') date: any;

    constructor() { }

    ngOnInit() {
        this.date = moment(this.date).fromNow();
    }

}
