import { HttpClient } from '@angular/common/http';
import { StorageService } from './../storage/storage.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    baseServerUrl: any = environment.API_URL
    newNotificationRecieved: boolean = false
    allNotifications: any = [];

    constructor(
        public storageService: StorageService,
        public http: HttpClient,
    ) { }

    getAllUserNotifications() {
        console.log("UserService getCurrentLoggedInUserData called");

        if (this.storageService.getStorage('currentUser')) {
            return this.http.get(this.baseServerUrl + `/notifications/getAllNotifications/${JSON.parse(this.storageService.getStorage('currentUser'))._id}`).pipe(tap((data: any) => {
            }), catchError((err) => {
                throw (err)
            }))
        }
    }

}
