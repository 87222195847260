// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#container {
  text-align: center;
  padding-top: 5rem;
}

#container strong {
  font-size: 25px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  padding: 10px 0;
  margin: 0 auto;
}

#container a {
  text-decoration: none;
}

img {
  width: 150px;
  margin: 0 auto;
  padding: 10px 0;
}

ion-item {
  --background: rgb(115 44 168 / 0%);
}`, "",{"version":3,"sources":["webpack://./src/app/components/coming-soon/coming-soon.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;AACJ;;AAOE;EACE,eAAA;EACA,iBAAA;AAJJ;;AAOE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;AAJJ;;AAOE;EACE,qBAAA;AAJJ;;AAME;EACE,YAAA;EACA,cAAA;EACA,eAAA;AAHJ;;AAKE;EACE,kCAAA;AAFJ","sourcesContent":["#container {\n    text-align: center;\n    padding-top: 5rem;\n    // position: absolute;\n    // left: 0;\n    // right: 0;\n    // top: 50%;\n    // transform: translateY(-50%);\n  }\n  \n  #container strong {\n    font-size: 25px;\n    line-height: 26px;\n  }\n  \n  #container p {\n    font-size: 16px;\n    line-height: 22px; \n    color: #8c8c8c;\n    padding: 10px 0;\n    margin: 0 auto; \n }\n  \n  #container a {\n    text-decoration: none;\n  }\n  img{\n    width: 150px;\n    margin: 0 auto;\n    padding: 10px 0;\n  }\n  ion-item{\n    --background: rgb(115 44 168 / 0%);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
