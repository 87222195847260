import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-location-results',
    templateUrl: './location-results.component.html',
    styleUrls: ['./location-results.component.scss'],
})
export class LocationResultsComponent implements OnInit {

    @Input('locationResults') locationResults: any;
    @Output() selectedLocation = new EventEmitter<string>();
    constructor() { }

    ngOnInit() { }

    selectLocation(val) {
        this.selectedLocation.emit(val);
    }



}
