// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-all-results {
  zoom: 0.7;
}

.search-results-wrapper {
  min-width: 22rem;
  max-width: max-content;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.program-pic-list {
  object-fit: cover;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin: 0 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/search-results/search-results.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;AACJ;;AAEA;EACI,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;AACJ","sourcesContent":[".show-all-results {\n    zoom: 0.7;\n}\n\n.search-results-wrapper {\n    min-width: 22rem;\n    max-width: max-content;\n    padding: 1rem;\n    display: flex;\n    flex-direction: column;\n}\n\n.program-pic-list {\n    object-fit: cover;\n    width: 42px;\n    height: 42px;\n    border-radius: 50%;\n    margin: 0 1rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
