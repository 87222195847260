// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/tabs/tabs.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AACJ","sourcesContent":["ion-tab-bar {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
