import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
    selector: 'app-time-slot-entry',
    templateUrl: './time-slot-entry.component.html',
    styleUrls: ['./time-slot-entry.component.scss'],
})
export class TimeSlotEntryComponent implements OnInit {
    selectedTimeSlot: any;
    minTime: any;
    timeRange;
    selectedTimes;
    constructor(
        public popoverController: PopoverController
    ) { }

    ngOnInit() {
        console.log(moment(this.selectedTimes[this.selectedTimes.length]).utc().format('YYYY-MM-DD'));



    }

    selectTimeSlot(e) {
        console.log("🚀 ~ file: create-program.page.ts:226 ~ CreateProgramPage ~ selectTimeSlot ~ e:", e)
        this.selectedTimeSlot = e;
    }

    dismiss() {
        this.popoverController.dismiss(this.selectedTimeSlot)
    }

}
