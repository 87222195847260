import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {
    baseServerUrl: any = environment.API_URL

    constructor(
        private http: HttpClient,
    ) { }

    getCategories() {
        // return this.http.get(this.baseServerUrl + '/syllabus/getSyllabus').pipe(tap((data) => {
        //     console.log("🚀 ~ file: getSyllabus.service.ts:18 ~ getSyllabus ~ returnthis.http.post ~ data:", data)
        // }), catchError((err) => {
        //     console.log("🚀 ~ file: categories.service.ts:20 ~ CategoriesService ~ returnthis.http.post ~ err:", err)
        //     throw (err)
        // }))
        return this.http.get(this.baseServerUrl + '/categories').pipe(tap((data) => {
            console.log("🚀 ~ file: categories.service.ts:18 ~ CategoriesService ~ returnthis.http.post ~ data:", data)
        }), catchError((err) => {
            console.log("🚀 ~ file: categories.service.ts:20 ~ CategoriesService ~ returnthis.http.post ~ err:", err)
            throw (err)
        }))
    }

}
