import { Injectable, PLATFORM_ID, signal } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { StorageService } from '../storage/storage.service';
import { ModalController, ToastController } from '@ionic/angular';
import { CommonFunctionsService } from '../common/common-functions.service';
import { FcmService } from '../fcm/fcm.service';
import { SocketService } from '../socket/socket.service';


@Injectable({
    providedIn: 'root'
})
export class UserService {
    baseServerUrl: any = environment.API_URL
    currentUser: any = {}
    allLanguages: any = [];
    userBehaviourSubject: BehaviorSubject<any> = new BehaviorSubject({})
    userData$: Observable<any> = this.userBehaviourSubject.asObservable()

    isUserCallDone : boolean = false;
    constructor(
        public http: HttpClient,
        public toastController: ToastController,
        private storageService: StorageService,
        public commonFunctions: CommonFunctionsService,
        public fcm: FcmService,
        public socketService: SocketService
    ) { }


    getCurrentLoggedInUserData() {
        console.log("UserService getCurrentLoggedInUserData called");

        if (this.storageService.getStorage('currentUser')) {
            return this.http.get(this.baseServerUrl + `/users/${JSON.parse(this.storageService.getStorage('currentUser'))._id}`).pipe(tap((data: any) => {
            }), catchError((err) => {
                throw (err)
            }))
        }
    }

    getCurrentLoggedInUserDocuments() {
        if (this.storageService.getStorage('currentUser')) {
            return this.http.get(this.baseServerUrl + `/users/get-user-document/${JSON.parse(this.storageService.getStorage('currentUser'))._id}`).pipe(tap((data) => {

            }), catchError((err) => {
                throw (err)
            }))
        }
    }

    getCurrentLoggedInUserPaidPaymentHistory() {
        if (this.storageService.getStorage('currentUser')) {
            return this.http.get(this.baseServerUrl + `/users/get-user-payment-history-paid/${JSON.parse(this.storageService.getStorage('currentUser'))._id}`).pipe(tap((data) => {

            }), catchError((err) => {
                throw (err)
            }))
        }
    }

    getCurrentLoggedInUserRecievedPaymentHistory() {
        if (this.storageService.getStorage('currentUser')) {
            return this.http.get(this.baseServerUrl + `/users/get-user-payment-history-recieved/${JSON.parse(this.storageService.getStorage('currentUser'))._id}`).pipe(tap((data) => {

            }), catchError((err) => {
                throw (err)
            }))
        }
    }

    userSignUp(userSignupObject) {
        return this.http.post(this.baseServerUrl + '/signup', JSON.stringify(userSignupObject)).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    userLogin(userLoginObject) {
        return this.http.post(this.baseServerUrl + '/login', JSON.stringify(userLoginObject)).pipe(tap((res: any) => {
            if (res.hasOwnProperty('data')) {
                if (!res.data.hasOwnProperty('token') ||
                    res.data.token == null && res.data.token == "" ||
                    !res.data.hasOwnProperty('expiresIn') || res.data.expiresIn == null ||
                    res.data.expiresIn == "") {
                    return
                }

                const setTokenInStorageResponse = this.storageService.setStorage('token', res.data.token)
                const setExpInStorage = this.storageService.setStorage('tokenExpiration', res.data.expiresIn)
                const setUserObjInStorage = this.storageService.setStorage('currentUser', JSON.stringify(res.data.user))
                const setUserIdInStorage = this.storageService.setStorage('currentUserId', JSON.stringify(res.data.user._id))
                this.currentUser = res.data.user;
                // this.fcm.initializePushNotifService()
                //init socket setup here
                this.socketService.initSocket()
            }

        }), catchError((err) => {
            // alert("Something went wrong. Cannot login user at this time. Please try again later")
            throw (err)
        }))
    }

    userUpdate(userUpdateObject) {
        return this.http.patch(this.baseServerUrl + '/users/update-user' + `/${JSON.parse(this.storageService.getStorage('currentUser'))._id}`, JSON.stringify(userUpdateObject)).pipe(tap((data) => {
            // return this.http.patch(this.baseServerUrl + '/users/categories', JSON.stringify(userUpdateObject)).pipe(tap((data) => {

        }), catchError((err) => {
            throw (err)
        }))
    }

    verifyOTP(otpObject) {
        return this.http.post(this.baseServerUrl + '/verify-otp', JSON.stringify(otpObject)).pipe(tap((data) => {

        }), catchError((err) => {
            throw (err)
        }))
    }

    resendOTP(reqObject) {
        console.log("🚀 ~ file: user.service.ts:56 ~ UserService ~ returnthis.http.post ~ this.baseServerUrl:", this.baseServerUrl)
        return this.http.post(this.baseServerUrl + '/resend-otp', JSON.stringify(reqObject)).pipe(tap((data) => {
        }), catchError((err) => {
            throw (err)
        }))
    }

    resetPassword(resetPasswordObject) {
        return this.http.patch(this.baseServerUrl + '/users/reset-password', JSON.stringify(resetPasswordObject)).pipe(tap((data) => {
        }), catchError((err) => {
            throw (err)
        }))
    }

    authenticateSession() {
        return this.http.post(this.baseServerUrl + '/validate-session', JSON.stringify({})).pipe(tap((data) => {
        }), catchError((err) => {
            console.log("autheticate session api error block", err);

            throw (err)
        }))
    }

    languagesList() {
        console.log("System .... Languages");
        return this.http.get("assets/system-languages.json").pipe(
            tap((data) => {
                console.log("System .... Languages", data);
                this.allLanguages = data;
            }),
            catchError((err) => { throw (err) }))
    }

    async showcomontoast(msg) {
        const comontoast = await this.toastController.create({
            message: msg,
            duration: 1000,
        });
        comontoast.present();

        await comontoast.onDidDismiss().then(() => {
        });
    }

    displayInitialName(f_name, l_name) {
        return (f_name.slice(0, 1) + "" + l_name.slice(0, 1))
    }


    getProviderPublicData(providerId) {
        return this.http.get(this.baseServerUrl + `/users/get-provider-public-profile/${providerId}`).pipe(tap((data) => {

        }), catchError((err) => {
            throw (err)
        }))
    }
    getProviderPublicProgramlist(providerId) {
        return this.http.get(this.baseServerUrl + `/program/get-provider-program-list/${providerId}`).pipe(tap((data) => {

        }), catchError((err) => {
            throw (err)
        }))
    }

}
