export const MyConfig = {
    regex: {
        nameRegex: '^(?![ .]+$)[a-zA-Z0-9-,._\'!:;/<>"?=+\|`~{}@#$%^&*() ]*$', // no whitespace allowed and all the characters allowed
        emailRegex: "[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}",
        phoneRegex: "[0-9]{10}",
        passwordRegex: "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})",
        passwordRegex_Upper_case: "[A-Z]{1,}", // include atleast one uppercase character
        passwordRegex_Numeric: "[0-9]{1,}", // include atleast one Numeric character
        passwordRegex_Special_char: "[^a-zA-z0-9\s]{1,}", // include atleast one special character
        passwordRegex_Detect_spaces: "\s{1,}",// spaces are not allowed
        stateRegex: '^[A-Za-z]{2,}$', // only letters are allowed no numbers
        zipCodeRegex: '^[0-9]{5,}$' // only numbers allowed
    },
    error: {
        firstName: "Enter valid first name",
        lastName: "Enter valid last name",
        email: "Enter valid email",
        phone: "Enter valid phone number",
        password: "Enter valid password",
        confirmPassword: "Confirm password mismatch",
        fieldRequired: "Field required",
        validfieldRequired: "enter valid Field value",
        ageCheck: "User should be 18+ in age",
        verifyCode: "Invalid code. Try again.",
        verifyCodeEmpty: "Enter valid code",
    },
    message: {
        passwordMinLength: "Password having minimum 8 digit",
        passwordUpperCase: "Password must contain a upper case letter",
        passwordNumeric: "Password must contain a number",
        passwordSpecialCharacter: "Password must contain a special character"
    }
}