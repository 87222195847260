// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
h1 {
  text-align: center;
}

.guidelines {
  margin-left: 5rem !important;
  margin: 1rem;
}

.btns {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.space {
  margin-left: 1rem;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li:before {
  content: "•";
  /* Bullet point */
  color: white;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.section-title {
  font-weight: bold;
  font-size: larger;
  margin-top: 20px;
}

.note {
  margin-top: 20px;
  font-size: 0.9em;
}

.spacing {
  padding: 24px 24px 24px 42px;
}

.bot-space {
  margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/community-guidelines/community-guidelines.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,kBAAA;AAEJ;;AACA;EACI,4BAAA;EACA,YAAA;AAEJ;;AACA;EACI,kBAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;AAEJ;;AACA;EACI,iBAAA;AAEJ;;AAMA;EACI,qBAAA;EACA,eAAA;AAHJ;;AAUA;EACI,YAAA;EACA,iBAAA;EACA,YAAA;EACA,qBAAA;EACA,UAAA;EACA,iBAAA;AAPJ;;AAUA;EACI,iBAAA;EACA,iBAAA;EACA,gBAAA;AAPJ;;AAUA;EACI,gBAAA;EACA,gBAAA;AAPJ;;AAUA;EACI,4BAAA;AAPJ;;AAUA;EACI,gBAAA;AAPJ","sourcesContent":["h1 {\n    text-align: center;\n}\n\n.guidelines {\n    margin-left: 5rem !important;\n    margin: 1rem;\n}\n\n.btns {\n    position: absolute;\n    bottom: 0;\n    left: 50%;\n    transform: translateX(-50%);\n}\n\n.space {\n    margin-left: 1rem;\n}\n\n// h1,\n// h2,\n// h3 {\n//     color: white;\n// }\nul {\n    list-style-type: none;\n    padding-left: 0;\n}\n\nul li {\n    // margin-bottom: 10px;\n}\n\nul li:before {\n    content: \"\\2022\";\n    /* Bullet point */\n    color: white;\n    display: inline-block;\n    width: 1em;\n    margin-left: -1em;\n}\n\n.section-title {\n    font-weight: bold;\n    font-size: larger;\n    margin-top: 20px;\n}\n\n.note {\n    margin-top: 20px;\n    font-size: 0.9em;\n}\n\n.spacing {\n    padding: 24px 24px 24px 42px;\n}\n\n.bot-space {\n    margin-top: 2rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
