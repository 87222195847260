import { Component, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { ReviewsService } from 'src/app/services/reviews/reviews.service';

@Component({
    selector: 'app-allcourses',
    templateUrl: './allcourses.component.html',
    styleUrls: ['./allcourses.component.scss'],
})
export class AllcoursesComponent implements OnInit {
    reviews: any = []
    constructor(
        public modalCtrl: ModalController,
        public actionSheetCtrl: ActionSheetController,
        public alertController: AlertController,
        public reviewsService: ReviewsService
    ) {
        console.log("this.reviews", this.reviews);

    }

    ngOnInit() {
        console.log("this.reviews", this.reviews);
    }

    async alertConfirmAutoFill(course) {
        const alert = this.alertController.create({
            message: 'Are you sure you want to autofill the details from this course',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes',
                    handler: () => {
                        this.modalCtrl.dismiss(course);
                    }
                }
            ]
        });
        (await alert).present();
    }

    closeModal() {
        this.modalCtrl.dismiss()
    }

}
