// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  opacity: 0;
}

.size-27 {
  height: 27px;
  width: 27px;
}

.size-52 {
  height: 52px;
  width: 52px;
}

ion-icon {
  --ionicon-stroke-width: 3rem;
}

p {
  background: #ffffff;
  z-index: 10;
  position: sticky;
  top: 0;
}

.top-spacing {
  padding: 3rem 0 0 0;
  margin: 0;
}

.profile-pic {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 50%;
}

.new-notification {
  height: 12px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  background: red;
  position: absolute;
  top: 50px;
  right: 425px;
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header-bar/header-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACI,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,MAAA;AACJ;;AAEA;EAGI,mBAAA;EACA,SAAA;AADJ;;AAIA;EACI,YAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AADJ;;AAIA;EACI,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;AADJ","sourcesContent":["p {\n    opacity: 0;\n}\n\n.size-27 {\n    height: 27px;\n    width: 27px;\n}\n\n.size-52 {\n    height: 52px;\n    width: 52px;\n}\n\nion-icon {\n    --ionicon-stroke-width: 3rem;\n}\n\np {\n    background: #ffffff;\n    z-index: 10;\n    position: sticky;\n    top: 0;\n}\n\n.top-spacing {\n    // margin: 3rem 0;\n    // padding-top: 2rem;\n    padding: 3rem 0 0 0;\n    margin: 0;\n}\n\n.profile-pic {\n    height: 52px;\n    width: 52px;\n    object-fit: cover;\n    border-radius: 50%;\n}\n\n.new-notification {\n    height: 12px;\n    border-radius: 50%;\n    aspect-ratio: 1/1;\n    background: red;\n    position: absolute;\n    top: 50px;\n    right: 425px;\n    z-index: 100;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
