import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ProgramService } from 'src/app/services/program/program.service';
import { UserService } from 'src/app/services/user/user.service';
import { catchError, tap } from 'rxjs';
import { Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { MyConfig } from 'src/assets/config/app-config';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent implements OnInit {

  myconfig: any = MyConfig;
  personalInfo: FormGroup;
 
  constructor(
    public modalCtrl: ModalController,
    public userService: UserService,
    public formBuilder: FormBuilder,) { }


  closeModal() {
    this.modalCtrl.dismiss()
  }

  ngOnInit() {
    this.initForm();
  }

initForm() {
    try {
        this.personalInfo = this.formBuilder.group({
            email: [this.userService.currentUser.email, [Validators.required]],
            first_name: [this.userService.currentUser.first_name, [Validators.required]],
            last_name: [this.userService.currentUser.last_name,  [Validators.required]],
            phone: [this.userService.currentUser.phone,  [Validators.required]],
            aboutme : [this.userService.currentUser.aboutme, [Validators.required]],
        }) 
    }
    catch (error) { }
}

 
 savePersonal(){
  if (this.personalInfo.valid ) {
    const updateabout = { 
      aboutme: this.personalInfo.get('aboutme').value, 
     };
      this.userService.userUpdate(updateabout)
          .subscribe((result:any) => {
            this.userService.currentUser.aboutme = this.personalInfo.get('aboutme').value;
            this.userService.showcomontoast(result.message);
            this.closeModal();
          });
        }
 }
 
}
