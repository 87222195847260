import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { StorageService } from 'src/app/services/storage/storage.service';

import { UserService } from 'src/app/services/user/user.service';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})


export class AuthGuard implements CanActivate {
    constructor(
        public userService: UserService,
        public storageService: StorageService,
        public router: Router,
    ) {

    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.validateSession().then(res => res)

        throw new Error('Method not implemented.');

    }



    async validateSession() {
        return new Promise<boolean>(async (resolve, reject) => {
            this.userService.authenticateSession().subscribe((isAuthorised: any) => {
                console.log("inside route guard ", isAuthorised)
                if (!isAuthorised ||
                    isAuthorised == null ||
                    isAuthorised == undefined
                ) {
                    //  verificationLoading.dismiss()
                    console.log("inside route guard non auth", isAuthorised)
                    this.router.navigate(['/welcome']);
                    resolve(false)

                }
                else {
                    if (isAuthorised.success == true) {
                        resolve(true)
                    }
                    else {
                        this.router.navigate(['/welcome']);
                        resolve(false)
                    }
                }
            }, (error) => {
                console.log("inside route guard error", error)
                this.router.navigate(['/welcome']);
                resolve(false)
            })



        })
    }
}



