// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  height: inherit;
  width: inherit;
  object-fit: cover;
  border-radius: 50%;
}

.profile_initials {
  height: 60px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
  background: #732da9;
  display: flex;
}
.profile_initials span {
  align-self: center;
  margin: 0 auto;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 970px) {
  .profile_initials {
    height: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile-avatar/profile-avatar.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AACA;EACI,YAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;AAEJ;AADI;EACI,kBAAA;EACA,cAAA;EACA,eAAA;EACA,+BAAA;AAGR;;AAAA;EACI;IACI,YAAA;EAGN;AACF","sourcesContent":["img {\n    height: inherit;\n    width: inherit;\n    object-fit: cover;\n    border-radius: 50%;\n}\n.profile_initials{\n    height: 60px;\n    aspect-ratio: 1;\n    object-fit: cover;\n    border-radius: 50%;\n    background: #732da9;\n    display: flex;\n    span{\n        align-self: center;\n        margin: 0 auto;\n        font-size: 20px;\n        color: rgb(255 255 255 / 70%);\n    }\n}\n@media screen and (max-width: 970px) {\n    .profile_initials{\n        height: 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
