export const environment = {
    // production: false,
    // API_URL: 'https://talent-share-backend-systest-a807c431d377.herokuapp.com',
    // // API_URL: 'http://localhost:192.168.1.163',
    // Client_URL: 'https://talent-share-frontend-systest-2dbcc3a7a810.herokuapp.com',
    // stripe: {
    //     publicKey: 'pk_test_51Nuo8ZCyjztih5KzVijJvItWaJcftEwJ7sRhTNJaB6x4mpBTdtGWSSJlNAsf13AoHOAyynIRuPoshLoMnFvnCCbs00WKkjOnZz',
    // }
    production: false,
    API_URL: 'https://talent-share-backend-prod-1350b1d2deee.herokuapp.com',
    Client_URL: 'https://talent-share-frontend-prod-dccb8acf7b69.herokuapp.com',
    stripe: {
        publicKey: 'pk_live_51Nuo8ZCyjztih5KzjuW1KrNYrA2a8GurEzRFL1VEt1X1F5VWA7jUXCkUvr8pU4WoCkLM7rzZqVi8C7mFP05PAPz4007ULMvvG0',
    }
};