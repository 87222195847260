// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.float-left {
  float: left;
}

.float-right {
  float: right;
}

app-location-results {
  background: white;
  border-radius: 1rem;
  width: 100%;
  overflow: hidden;
}

ion-label {
  padding-inline-start: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/location/location.page.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AACA;EACI,YAAA;AAEJ;;AAAA;EACI,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;AAGJ;;AAAA;EACI,0BAAA;AAGJ","sourcesContent":[".float-left{\n    float: left;\n}\n.float-right{\n    float: right;\n}\napp-location-results {\n    background: white;\n    border-radius: 1rem;\n    width: 100%;\n    overflow: hidden;\n}\n\nion-label{\n    padding-inline-start: 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
