import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safeHtml/safe-html.pipe';
import { SafePdfPipe } from './safePdf/safe-pdf.pipe';


@NgModule({
    declarations: [SafeHtmlPipe, SafePdfPipe],
    imports: [
        CommonModule
    ],
    exports: [SafeHtmlPipe, SafePdfPipe]
})
export class PipesModule { }
