// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-wrapper {
  overflow: auto;
  margin-bottom: 1rem;
}

.section-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  position: sticky;
  left: 0;
}

.section-header-label {
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.expand-section {
  font-weight: 500;
  font-size: 13px;
  color: var(--ion-color-primary);
}

.category-list {
  padding: 0 1rem 1rem 1rem;
  display: flex;
  width: max-content;
  gap: 1rem;
  overflow: auto;
}

.category-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.category-pic {
  width: 24px;
  height: 24px;
}

.category-pic-wrapper {
  width: 64px;
  height: 64px;
  border: 1px solid var(--ion-color-primary);
  border-radius: 10px;
  display: grid;
  place-content: center;
}

.category-name {
  font-weight: 500;
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/categories-section/categories-section.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,gBAAA;EACA,OAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,+BAAA;AACJ;;AAEA;EACI,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,SAAA;EACA,cAAA;AACJ;;AAEA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,0CAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".section-wrapper {\n    overflow: auto;\n    margin-bottom: 1rem;\n}\n\n.section-header-wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 1rem;\n    position: sticky;\n    left: 0;\n}\n\n.section-header-label {\n    font-weight: 700;\n    font-size: 24px;\n    display: flex;\n    align-items: center;\n    gap: 6px;\n}\n\n.expand-section {\n    font-weight: 500;\n    font-size: 13px;\n    color: var(--ion-color-primary);\n}\n\n.category-list {\n    padding: 0 1rem 1rem 1rem;\n    display: flex;\n    width: max-content;\n    gap: 1rem;\n    overflow: auto;\n}\n\n.category-wrapper {\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n    align-items: center;\n}\n\n.category-pic {\n    width: 24px;\n    height: 24px;\n}\n\n.category-pic-wrapper {\n    width: 64px;\n    height: 64px;\n    border: 1px solid var(--ion-color-primary);\n    border-radius: 10px;\n    display: grid;\n    place-content: center;\n}\n\n.category-name {\n    font-weight: 500;\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
