import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AnnouncementsService } from 'src/app/services/announcements/announcements.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
    selector: 'app-add-announcement',
    templateUrl: './add-announcement.component.html',
    styleUrls: ['./add-announcement.component.scss'],
})
export class AddAnnouncementComponent implements OnInit {
    announcementTitle: any = '';
    announcementMessage: any = '';
    AuthorId: any = '';
    programId;
    enrollments;
    announcement;
    program_name;
    submitAttempt: boolean = false;
    isEditAnnouncement: boolean = false;
    constructor(
        public alertController: AlertController,
        public modalCtrl: ModalController,
        public storageService: StorageService,
        public announcementsService: AnnouncementsService
    ) { }

    ngOnInit() {
        this.AuthorId = JSON.parse(this.storageService.getStorage('currentUser'))._id
        this.checkIfEditingAnnouncement();
    }

    checkIfEditingAnnouncement() {
        if (this.announcement != undefined && this.announcement != '' && this.announcement != null) {
            this.isEditAnnouncement = true;
            this.announcementMessage = this.announcement.announcement_message;
            this.announcementTitle = this.announcement.announcement_title;
        }
    }

    assignAnnouncementTitle(e) {
        this.announcementTitle = e.target.value;
    }

    assignAnnouncementMessage(e) {
        this.announcementMessage = e.target.value;
    }

    checkIfAnnouncementTitleFilled() {
        return this.announcementTitle == '' ? false : true
    }

    checkIfAnnouncementMessageFilled() {
        return this.announcementMessage == '' ? false : true
    }

    checkIfTextFilledBeforeAddAnnouncement() {
        if (this.checkIfAnnouncementTitleFilled() && this.checkIfAnnouncementMessageFilled() && this.isEditAnnouncement == false) this.alertConfirmPublishAnnouncement();
        if (this.checkIfAnnouncementTitleFilled() && this.checkIfAnnouncementMessageFilled() && this.isEditAnnouncement == true) this.alertConfirmEditPublishAnnouncement();
        else this.submitAttempt = true;
    }

    checkIfTextEmptyBeforeCloseModal() {
        if (!this.checkIfAnnouncementMessageFilled() && !this.checkIfAnnouncementTitleFilled()) this.closeModal();
        else this.alertConfirmCloseModal();
    }

    addAnnouncement() {
        let publishObj = {
            createdAt: new Date(),
            updatedAt: new Date(),
            disable: false,
            author_id: this.AuthorId,
            program_id: this.programId,
            announcement_title: this.announcementTitle,
            announcement_message: this.announcementMessage,
            enrollments: this.enrollments,
            program_name: this.program_name

        }

        this.announcementsService.PostAnnouncement(publishObj).subscribe((res: any) => {
            if (res && res.success == true) this.alertAnnouncementPublishedSuccessfully()
        })
    }

    editAnnouncement() {
        let publishObj = {
            updatedAt: new Date(),
            announcement_title: this.announcementTitle,
            announcement_message: this.announcementMessage,
        }

        this.announcementsService.UpdateAnnouncement(this.announcement._id, publishObj).subscribe((res: any) => {
            if (res && res.success == true) this.alertAnnouncementSavedAndPublishedSuccessfully()
        })
    }

    async alertAnnouncementPublishedSuccessfully() {
        const alert = this.alertController.create({
            message: 'Announcement published successfully!!',
            buttons: [
                {
                    text: 'ok',
                    role: 'cancel',
                    handler: () => { this.closeModal() }
                }
            ]
        });
        (await alert).present();
    }

    async alertAnnouncementSavedAndPublishedSuccessfully() {
        const alert = this.alertController.create({
            message: 'Announcement saved and published successfully!!',
            buttons: [
                {
                    text: 'ok',
                    role: 'cancel',
                    handler: () => { this.closeModal() }
                }
            ]
        });
        (await alert).present();
    }

    async alertConfirmPublishAnnouncement() {
        const alert = this.alertController.create({
            message: 'Confirm publish announcement',
            buttons: [
                {
                    text: 'No, cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes, publish',
                    handler: () => {
                        this.addAnnouncement()
                    }
                }
            ]
        });
        (await alert).present();
    }

    async alertConfirmEditPublishAnnouncement() {
        const alert = this.alertController.create({
            message: 'Confirm save and publish announcement',
            buttons: [
                {
                    text: 'No, cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes, publish',
                    handler: () => {
                        this.editAnnouncement()
                    }
                }
            ]
        });
        (await alert).present();
    }

    async alertConfirmCloseModal() {
        const alert = this.alertController.create({
            message: 'are you sure you want to discard the announcement?',
            buttons: [
                {
                    text: 'No, cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes, discard',
                    handler: () => {
                        this.closeModal()
                    }
                }
            ]
        });
        (await alert).present();
    }


    closeModal() {
        this.modalCtrl.dismiss()
    }

}
