import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { SearchService } from 'src/app/services/search/search.service';

@Component({
    selector: 'app-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {

    constructor(
        public searchService: SearchService,
        public navigationService: NavigationService
    ) { }

    ngOnInit() { }

    hideSearchResultList() {
        this.searchService.hideSearchResultsList();
    }


}
