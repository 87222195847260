import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})


export class StorageService {

    localStorageObj: any = {
        'token': '',
        'tokeExpiration': '',
        'currentUser': '',
        'currentUserId': ''
    }
    
    locationStorageObj: any = {
        'selectedlocation' :  '',
        'selectedCity' :  '',
        'selectedCountry' :  '',
        'selectedLatitude' :  '',
        'selectedLongitude' :  '',
        'isCurrentLocationSet' : '',
    }
    constructor() { }

    public setStorage(key, value) {
        console.log("🚀 ~ file: storage.service.ts:19 ~ setStorage ~ key, value:", key, value)
        if (this.localStorageObj.hasOwnProperty(key)) {
            let hashedKey = key
            let hashedValue = value
            localStorage.setItem(hashedKey, hashedValue)
            return true
        }
        else {
            return false
        }
    }

    public getStorage(key) {
        let hashedKey = key
        let response = localStorage.getItem(hashedKey)

        if (response != null || response != undefined) {
            let decryptedValue
            decryptedValue = response
            return decryptedValue
        }
        else {
            return false
        }
    }

    //for location values
    public setLocationStorage(key, value) {
        console.log("🚀 ~ file: storage.service.ts:19 ~ setStorage ~ key, value:", key, value)
        if (this.locationStorageObj.hasOwnProperty(key)) {
            let hashedKey = key
            let hashedValue = value
            localStorage.setItem(hashedKey, hashedValue)
            return true
        }
        else {
            return false
        }
    }

    //for location values
    public getLocationStorage(key) {
        let hashedKey = key
        let response = localStorage.getItem(hashedKey)

        if (response != null || response != undefined) {
            let decryptedValue
            decryptedValue = response
            return decryptedValue
        }
        else {
            return false
        }
    }
}

