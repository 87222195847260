import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CategoriesSectionComponent } from './categories-section/categories-section.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { OauthLoginComponent } from './oauth-login/oauth-login.component';
import { ProfileAvatarComponent } from './profile-avatar/profile-avatar.component';
import { ProgramSectionComponent } from './program-section/program-section.component';
import { TabsComponent } from './tabs/tabs.component';
import { WebHeaderBarComponent } from './web-header-bar/web-header-bar.component';
import { LocationResultsComponent } from './location-results/location-results.component';
import { TimeSlotEntryComponent } from './time-slot-entry/time-slot-entry.component';
import { MyReviewOptionsComponent } from './my-review-options/my-review-options.component';
import { EnrollmentSortOptionsComponent } from './enrollment-sort-options/enrollment-sort-options.component';
import { ProgramPreviewComponent } from './program-preview/program-preview.component';
import { PipesModule } from '../pipes/pipes.module';
import { ShowAllAnnouncementsComponent } from './show-all-announcements/show-all-announcements.component';
import { ShowAllReviewsComponent } from './show-all-reviews/show-all-reviews.component';
import { ShowAllCategoriesComponent } from './show-all-categories/show-all-categories.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { AddAnnouncementComponent } from './add-announcement/add-announcement.component';
import { AddReviewComponent } from './add-review/add-review.component';
import { NgxStripeModule } from 'ngx-stripe';
import {
    StripeCardElementOptions,
    StripeElementsOptions,
    PaymentIntent,
} from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { PersonalInfoComponent } from './user-profile/personal-info/personal-info.component';
import { EducationalComponent } from './user-profile/educational/educational.component';
import { ProfesstionalComponent } from './user-profile/professtional/professtional.component';
import { AllcoursesComponent } from './allcourses/allcourses.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { HomeWallProviderComponent } from './home-wall-provider/home-wall-provider.component';
import { PaymentSummaryComponent } from './payment-summary/payment-summary.component';
import { DateDisplayComponent } from './date-display/date-display.component';
import { ShowEnrollmentComponent } from './show-enrollment/show-enrollment.component';
import { CommunityGuidelinesComponent } from './community-guidelines/community-guidelines.component';

@NgModule({
    declarations: [
        OauthLoginComponent,
        HeaderBarComponent,
        ProgramSectionComponent,
        CategoriesSectionComponent,
        WebHeaderBarComponent,
        ProfileAvatarComponent,
        TabsComponent,
        LocationResultsComponent,
        TimeSlotEntryComponent,
        MyReviewOptionsComponent,
        EnrollmentSortOptionsComponent,
        ProgramPreviewComponent,
        ShowAllAnnouncementsComponent,
        ShowAllReviewsComponent,
        ShowAllCategoriesComponent,
        SearchResultsComponent,
        AddAnnouncementComponent,
        AddReviewComponent,
        AllcoursesComponent,
        PaymentFormComponent,
        PersonalInfoComponent,
        EducationalComponent,
        ProfesstionalComponent,
        ComingSoonComponent,
        HomeWallProviderComponent,
        PaymentSummaryComponent,
        DateDisplayComponent,
        ShowEnrollmentComponent,
        CommunityGuidelinesComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxStripeModule.forRoot(environment.stripe.publicKey),
        PipesModule
    ],
    exports: [
        OauthLoginComponent,
        HeaderBarComponent,
        ProgramSectionComponent,
        CategoriesSectionComponent,
        WebHeaderBarComponent,
        ProfileAvatarComponent,
        TabsComponent,
        LocationResultsComponent,
        TimeSlotEntryComponent,
        MyReviewOptionsComponent,
        EnrollmentSortOptionsComponent,
        ProgramPreviewComponent,
        ShowAllAnnouncementsComponent,
        ShowAllReviewsComponent,
        ShowAllCategoriesComponent,
        SearchResultsComponent,
        AddAnnouncementComponent,
        AddReviewComponent,
        AllcoursesComponent,
        ComingSoonComponent,
        HomeWallProviderComponent,
        PaymentSummaryComponent,
        DateDisplayComponent,
        ShowEnrollmentComponent,
        CommunityGuidelinesComponent

    ]
    // entryComponents: [
    //     OauthLoginComponent,
    //     HeaderBarComponent,
    //     ProgramSectionComponent,
    //     CategoriesSectionComponent,
    //     WebHeaderBarComponent,
    //     ProfileAvatarComponent,
    //     TabsComponent,
    //     LocationResultsComponent,
    //     TimeSlotEntryComponent,
    //     MyReviewOptionsComponent,
    //     EnrollmentSortOptionsComponent
    // ]
})
export class ComponentsModule { }