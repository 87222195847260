// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button:nth-child(1) {
  margin: 0 24px 24px 24px;
}

ion-button:nth-child(2) {
  margin: 24px;
}

ion-button:nth-child(3) {
  margin: 24px;
}

ion-button.clr-google {
  --background: #EB5757;
}

ion-button.clr-facebook {
  --background: #1977F2;
}

ion-button.clr-linkedin {
  --background: #0B65C2;
}`, "",{"version":3,"sources":["webpack://./src/app/components/oauth-login/oauth-login.component.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ","sourcesContent":["ion-button:nth-child(1) {\n    margin: 0 24px 24px 24px\n}\n\nion-button:nth-child(2) {\n    margin: 24px\n}\n\nion-button:nth-child(3) {\n    margin: 24px\n}\n\nion-button.clr-google {\n    --background: #EB5757;\n}\n\nion-button.clr-facebook {\n    --background: #1977F2;\n}\n\nion-button.clr-linkedin {\n    --background: #0B65C2;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
