import { Component, OnInit } from '@angular/core';
import { LocationFlowService } from 'src/app/services/location/location-flow.service';
import { ModalController } from '@ionic/angular';
import { CommonFunctionsService } from 'src/app/services/common/common-functions.service';
import { StorageService } from 'src/app/services/storage/storage.service';

declare var google;

@Component({
  selector: 'app-location',
  templateUrl: './location.page.html',
  styleUrls: ['./location.page.scss'],
})
export class LocationPage implements OnInit {

  googleAutoComplete: any;
  googlePlaceService: any;
  locationResults: any;
  displaylocation: any;

  constructor(
    public modalCtrl: ModalController,
    public storageService: StorageService,
    public commonFunctions: CommonFunctionsService,
    public locationFlowService: LocationFlowService,) { 
      this.googleAutoComplete = new google.maps.places.AutocompleteService();
      this.googlePlaceService = new google.maps.places.PlacesService(document.createElement('div'));

    }

  ngOnInit() {

  }
  ionViewWillEnter(){
    this.displaylocation = this.storageService.getLocationStorage("selectedlocation")
  }

  getCurrentLocation(){
    this.locationFlowService.checkPlatformTypeLocation();
  }

  getLocationResults(val) {
    if (val != '') {
        this.googleAutoComplete.getPlacePredictions({ input: val, componentRestrictions: { country: ["us"] } }, (predictions, status) => {

            this.locationResults = predictions;
        })
    } else {
        this.clearLocationResults();
    }
}

clearLocationResults() {
  this.locationResults = [];
}

setSelectedLocation(e) {
  console.log("🚀 ~ file: create-program.page.ts:268 ~ CreateProgramPage ~ setSelectedLocation ~ e:", e)
  if (e.description != '') {
        console.log(e.description)
        this.locationFlowService.getManualLocation(e.description,e.place_id)
      this.clearLocationResults();
  } else {
  }
}

setVenueEntered() {
}

  skiplocation(){
    // this.locationFlowService.selectedlocation = "";
    // this.locationFlowService.selectedLatitude = "";
    // this.locationFlowService.selectedLongitude = "";
    this.modalCtrl.dismiss();
  }


}
