import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ProgramService } from 'src/app/services/program/program.service';
import { UserService } from 'src/app/services/user/user.service';
import { catchError, tap } from 'rxjs';
import { Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { MyConfig } from 'src/assets/config/app-config';

@Component({
    selector: 'app-educational',
    templateUrl: './educational.component.html',
    styleUrls: ['./educational.component.scss'],
})
export class EducationalComponent implements OnInit {

    myconfig: any = MyConfig;
    educationalInfo: FormGroup;
    submitAttempt: boolean = false;
    card;


    constructor(
        public modalCtrl: ModalController,
        public userService: UserService,
        public formBuilder: FormBuilder,) { }


    closeModal() {
        this.modalCtrl.dismiss()
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        try {
            this.educationalInfo = this.formBuilder.group({
                school_name: [this.userService.currentUser.user_education.school_name,],
                college_university_name: [this.userService.currentUser.user_education.college_university_name,],
                user_skills: [this.userService.currentUser.user_skills,],
                user_acheivements: [this.userService.currentUser.user_acheivements,],
                user_languages: [this.userService.currentUser.user_languages,],
            })
        }
        catch (error) { }
    }

    save() {
        if (this.card == 'education') this.saveEducation();
        else if (this.card == 'awards rewards achivements') this.saveAcheivements();
        else if (this.card == 'skills passions interests') this.saveSkills();
        else if (this.card == 'languages') this.saveLanguages();
    }

    saveEducation() {
        if (this.educationalInfo.get('school_name').value != '' && this.educationalInfo.get('college_university_name').value != '') {
            const updateabout = {
                user_education: {
                    school_name: this.educationalInfo.get('school_name').value,
                    college_university_name: this.educationalInfo.get('college_university_name').value,
                }
            };

            this.userService.userUpdate(updateabout)
                .subscribe((result: any) => {
                    this.userService.currentUser.user_education.school_name = this.educationalInfo.get('school_name').value;
                    this.userService.currentUser.user_education.college_university_name = this.educationalInfo.get('college_university_name').value;
                    this.userService.showcomontoast(result.message);
                    this.closeModal();
                });
        } else {
            this.submitAttempt = true;
        }

    }

    saveAcheivements() {
        console.log("saveAcheivements called");

        if (this.educationalInfo.get('user_acheivements').value != '') {
            const updateabout = {
                user_acheivements: this.educationalInfo.get('user_acheivements').value,
            };

            this.userService.userUpdate(updateabout)
                .subscribe((result: any) => {
                    this.userService.currentUser.user_acheivements = this.educationalInfo.get('user_acheivements').value;
                    this.userService.showcomontoast(result.message);
                    this.closeModal();
                });
        } else {
            this.submitAttempt = true;
        }

    }

    saveSkills() {
        if (this.educationalInfo.get('user_skills').value != '') {
            const updateabout = {
                user_skills: this.educationalInfo.get('user_skills').value,
            };

            this.userService.userUpdate(updateabout)
                .subscribe((result: any) => {
                    this.userService.currentUser.user_skills = this.educationalInfo.get('user_skills').value;
                    this.userService.showcomontoast(result.message);
                    this.closeModal();
                });
        } else {
            this.submitAttempt = true;
        }

    }

    saveLanguages() {
        if (this.educationalInfo.get('user_languages').value.length > 0) {
            const updateabout = {
                user_languages: this.educationalInfo.get('user_languages').value,
            };

            this.userService.userUpdate(updateabout)
                .subscribe((result: any) => {
                    this.userService.currentUser.user_languages = this.educationalInfo.get('user_languages').value;
                    this.userService.showcomontoast(result.message);
                    this.closeModal();
                });
        } else {
            this.submitAttempt = true;
        }

    }
}
