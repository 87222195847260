import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { StripeCardNumberComponent, STRIPE_OPTIONS, StripeService } from 'ngx-stripe';
import { ProgramService } from 'src/app/services/program/program.service';
import { UserService } from 'src/app/services/user/user.service';
import { catchError, tap } from 'rxjs';
import { Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { ModalController } from '@ionic/angular';
import { MyConfig } from 'src/assets/config/app-config';


@Component({
    selector: 'app-payment-summary',
    templateUrl: './payment-summary.component.html',
    styleUrls: ['./payment-summary.component.scss'],
})
export class PaymentSummaryComponent implements OnInit {
    courseDetails;
    transactionTaxes: any = '';
    transactionTotal: any = '';
    taxPercentage: any = 20;

    constructor(public userService: UserService,
        public alertController: AlertController,
        public programService: ProgramService,
        public modalCtrl: ModalController,
        private http: HttpClient,
        private fb: FormBuilder,
        private stripeService: StripeService,
        private paymentService: PaymentService) {

    }

    ngOnInit() {
        console.log(this.courseDetails)

        this.transactionTaxes = Number((this.courseDetails.program_cost * (this.taxPercentage / 100)).toFixed(2));
        this.transactionTotal = Number(this.courseDetails.program_cost) + Number(this.transactionTaxes);
    }

    checkToShowTotalTransactionTrailingZeros() {
        console.log("🚀 ~ file: payment-summary.component.ts:48 ~ PaymentSummaryComponent ~ checkToShow00 ~ this.transactionTotal.toString().includes('.'):", this.transactionTotal.toString().includes('.'))
        return this.transactionTotal.toString().includes('.');
    }
    checkToShowTransactionTaxTrailingZeros() {
        console.log("🚀 ~ file: payment-summary.component.ts:48 ~ PaymentSummaryComponent ~ checkToShow00 ~ this.transactionTotal.toString().includes('.'):", this.transactionTotal.toString().includes('.'))
        return this.transactionTaxes.toString().includes('.');
    }


    closeModal(action) {
        this.modalCtrl.dismiss(this.transactionTotal, action)
    }



}
