// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle {
  position: absolute;
  width: 55px;
  height: 55px;
  background: var(--ion-color-primary);
  border-radius: 50%;
  z-index: -1;
  transition: ease 0.5s;
  left: 50%;
  transform: translateX(-50%);
}

.tab-selected {
  color: var(--ion-color-custom-primary);
  --color-selected: var(--ion-color-custom-primary);
}

.new-notification {
  height: 8px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  background: red;
  position: absolute;
  top: 14px;
  right: 44%;
  z-index: 100;
}

ion-tab-button {
  --color: var(--ion-color-custom-secondary);
  --color-selected: var(--ion-color-primary);
}

ion-tab-bar {
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.25);
  height: 60px;
  padding: 0 16px;
}

ion-tab-bar {
  padding-bottom: 24px;
}

.white {
  color: white;
}

.talent-tab-icon {
  height: 40px;
}

.font-12 {
  font-size: 11px;
  width: 40px;
}

@media screen and (min-width: 970px) {
  .show-tab-bar {
    display: none;
  }
  ion-tab-bar {
    padding-bottom: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/tabs/tabs.page.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,kBAAA;EACA,WAAA;EACA,qBAAA;EACA,SAAA;EACA,2BAAA;AACJ;;AAEA;EACI,sCAAA;EACA,iDAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;AACJ;;AAEA;EACI,0CAAA;EACA,0CAAA;AACJ;;AAEA;EACI,6CAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAKA;EACI,YAAA;AAFJ;;AAKA;EACI,eAAA;EACA,WAAA;AAFJ;;AAKA;EACI;IACI,aAAA;EAFN;EAKE;IACI,iBAAA;EAHN;AACF","sourcesContent":[".circle {\n    position: absolute;\n    width: 55px;\n    height: 55px;\n    background: var(--ion-color-primary);\n    border-radius: 50%;\n    z-index: -1;\n    transition: ease 0.5s;\n    left: 50%;\n    transform: translateX(-50%);\n}\n\n.tab-selected {\n    color: var(--ion-color-custom-primary);\n    --color-selected: var(--ion-color-custom-primary);\n}\n\n.new-notification {\n    height: 8px;\n    border-radius: 50%;\n    aspect-ratio: 1/1;\n    background: red;\n    position: absolute;\n    top: 14px;\n    right: 44%;\n    z-index: 100;\n}\n\nion-tab-button {\n    --color: var(--ion-color-custom-secondary);\n    --color-selected: var(--ion-color-primary);\n}\n\nion-tab-bar {\n    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.25);\n    height: 60px;\n    padding: 0 16px;\n}\n\nion-tab-bar {\n    padding-bottom: 24px;\n}\n\n.white {\n    color: white;\n}\n\n// .primary {\n//     color: black;\n// }\n.talent-tab-icon {\n    height: 40px;\n}\n\n.font-12 {\n    font-size: 11px;\n    width: 40px;\n}\n\n@media screen and (min-width: 970px) {\n    .show-tab-bar {\n        display: none;\n    }\n\n    ion-tab-bar {\n        padding-bottom: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
