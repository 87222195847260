import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-review-options',
  templateUrl: './my-review-options.component.html',
  styleUrls: ['./my-review-options.component.scss'],
})
export class MyReviewOptionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
