import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class CommonFunctionsService {
    loadingdiv;

    public reportAlertMsg;
    constructor(private loadingCtrl: LoadingController,
        private toastController: ToastController,
        public platform: Platform,
        private alertController: AlertController) { }

    async showLoading(msg) {
        this.loadingdiv = await this.loadingCtrl.create({
            message: msg,
            cssClass: "loadinvElement"
        });

        this.loadingdiv.present();
    }
    async hideLoading() {
        this.loadingdiv.dismiss();
        console.log("hide error msg")
    }

    async presentToast(msg) {
        const toastElement = await this.toastController.create({
            message: msg,
            duration: 2000,
            position: 'bottom',
            mode: 'md',
            cssClass: "toastElement"
        });

        await toastElement.present();
    }

    async showErrorReporAlert(msg) {

        this.reportAlertMsg = await this.alertController.create({
            header: '',
            subHeader: '',
            message: msg,
            buttons: [
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        console.log('Alert confirmed');
                    },
                },]
        });

        await this.reportAlertMsg.present();

    }


    checkPlatform() {
        if (this.platform.platforms().some(v => v == "ios")) {
            return ("ios")
        }
        else if (this.platform.platforms().some(v => v == "android")) {
            return ("android")
        }
        else {
            return ("web")
        }
    }
}
