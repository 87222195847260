// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code {
  width: 15%;
  float: left;
}

.code-input-height {
  height: 58px;
  display: grid;
  place-content: end;
}

ion-header {
  padding-top: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/user-profile/personal-info/personal-info.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,WAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,kBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":[".code {\n    width: 15%;\n    float: left;\n}\n\n.code-input-height {\n    height: 58px;\n    display: grid;\n    place-content: end;\n}\n\nion-header {\n    padding-top: 4rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
